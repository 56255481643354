import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faMicrochip,
  faAddressCard,
  faExchangeAlt,
  faLock,
  faIndustry,
  faCogs,
  faPeopleGroup,
  faMobile,
  faRetweet,
  faUser,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

export const routes = [
  {
    path: "/",
    name: "Home",
    icon: <FontAwesomeIcon icon={faHome} />,
  },
  {
    path: "/genai",
    name: "GenAI",
    icon: <FontAwesomeIcon icon={faMicrochip} />,
    // icon : <FontAwesomeIcon icon="fa-sharp fa-solid fa-microchip-ai" />
  },
  {
    path: "/manageCloud",
    name: "Manage Cloud",
    icon: <FontAwesomeIcon icon={faAddressCard} />,
  },
  {
    path: "/migrations",
    name: "Migrations",
    icon: <FontAwesomeIcon icon={faExchangeAlt} />,
  },
  {
    path: "/securityCompliance",
    name: "Security, Compliance, and Assessments",
    icon: <FontAwesomeIcon icon={faLock} />,
  },
  {
    path: "/industry",
    name: "Industry",
    icon: <FontAwesomeIcon icon={faIndustry} />,
  },
  {
    path: "/professionalServices",
    name: "Professional Services",
    icon: <FontAwesomeIcon icon={faCogs} />,
  },
  {
    path: "/rpo",
    name: "RPO",
    icon: <FontAwesomeIcon icon={faPeopleGroup} />,
  },
  {
    path: "/developmenttestingservices",
    name: "Development & Testing Services",
    icon: <FontAwesomeIcon icon={faMobile} />,
  },
  {
    path: "/EnterpriseApplications",
    name: "Enterprise Applications",
    icon: <FontAwesomeIcon icon={faRetweet} />,
  },
  {
    path: "/AboutUs",
    name: "About Us",
    icon: <FontAwesomeIcon icon={faUser} />,
  },
  {
    path: "/ContactUs",
    name: "Contact Us",
    icon: <FontAwesomeIcon icon={faEnvelope} />,
  },
];
