import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import './contactus.css';

const contactus = () =>{
    return (
      <div className="cu">
        
          <h2>Contact us for help?</h2>
          <p>
            Contact with us through our representative or submit a business
            inquiry online.
          </p>
          <p>
            <a
              href="/ContactUs"
              style={{ fontSize: "25px", textDecoration: "none" }}
            >
              
              <b>Contact Us</b>
            </a>
            <FontAwesomeIcon
              icon={faAngleDoubleRight}
              style={{ marginLeft: "5px", color: "red" }}
            />
          </p>
        </div>
      
    );
};
export default contactus;