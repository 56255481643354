import React from "react";
import Myimage from "../../assets/images/Life-Sciences.jpg";

import "../../views/PageContent.css";
import ContactUs from "../../component/contactus";

import ServiceHeader from "../../component/ServiceHeader";
const LifeSciences = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="LIFE SCIENCES" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div className="imgg">
            <img src={Myimage} alt="legal" />
          </div>

          <div className="middle">
            <p>
              Most of the life sciences companies now face increasing consumer,
              portfolio, regulatory and operating challenges on a daily basis as
              they carry on their search for innovative health solutions. In
              order to create and sustain competitive differentiation and market
              dominance, the life sciences sector must meet the diverse
              challenges of your regulatory/life cycle strategy today while
              supporting innovations of tomorrow.
              <br></br>That’s a projection we’ve made in a Legal Business
              Report, titled The Next Five Years: Future of Legal Technology.
              The cloud is one of three key legal trends in the report that we
              forecast will have a considerable influence on the business of law
              in the near term.
              <br></br>
            </p>
            <p>
              <h5>CLOUD COMPUTING: UNDERSTANDING THE CLOUD HEMISPHERE</h5>
              The super cool tech word heard in all industries today is ‘The
              Cloud’. However most of us are concerned about the security and
              scared about the fringe guarding the cloud with your data in it.
              Few of the questions around the cloud have been addressed as
              below.
              <br></br>This article addresses to study the rise of cloud
              computing in the emerging life sciences domain including biotech
              and pharmaceutical sectors. The main objective has however been to
              determine the effects of cloud computing and the business impacts
              in an increasing global and competitive environment.
              <br></br>
            </p>

            <p>
              <h5>WHY SHOULD THE PHARMACY INDUSTRY EMBRACE THE CLOUD?</h5>
              Cloud computing finds it applications fitting into the picture
              perfect pharmaceutical regulatory industry because of the
              following reasons
              <ul>
                <li>Ever-growing datasets</li>
                <li>Unpredictable work packets and traffic patterns</li>
                <li>Demand for faster responses</li>
                <li>Reduced time to </li>
                <li>Business continuity</li>
              </ul>
            </p>
            <p>
              <h5>WHAT IS STOPPING THE TRANSITION?</h5>
              Regulatory bodies scrutinize enormous amounts of sensitive and
              confidential data. Cloud services have been deemed a mystery
              status when it comes to the visibility and security levels. Data
              in the cloud moves between various centres for various reasons
              load balancing, redundancy maintenance etc thus not making it a
              viable option for heavily regulated industries like the Pharma
              regulatory.
              <br></br>Especially the language of the legislation has always
              been slow to catch up with the sociotechnological shifts. This in
              turn makes it more challenging for the industry and the regulators
              to embrace new ways of maintaining the massive volumes of data in
              the wake of uncertainty if the new approach is either by letter or
              by spirit messing with the laws.
              <br></br>
            </p>
            <p>
              <h5>WHAT IS THE ACTUAL GAP?</h5>
              Finding and presenting the framework explaining the relation
              between the cloud provider and the customer has been an open
              challenge for the whole industry. Cloud providers are at large
              misinterpreted as “Outsourcing Providers”. Existing regulations
              dictate the interaction between service provider and their
              outsourcing partners, thereby impacting the ability of engaging
              with cloud providers. Despite of increasingly sophisticated
              virtual networks, many existing rules in regulated industries are
              mostly obsolete or hold little relevance to the security norms and
              standards for the new age technology.
              <br></br>
            </p>
            <p>
              <h5>WHERE TO START MIGRATION OF DATA TO THE CLOUD?</h5>
              Not everything that is IT related about an organization has to
              move onto a cloud. However taking time out to segment the existing
              datasets and concerned work packets on criteria like frequency of
              change, sensitivity enables the customer to effectively use the
              complementary solutions available in a big data environment.
              <br></br>
            </p>
            <p>
              <h5>WHAT TO LOOK INTO AND HOW TO SELL CLOUD?</h5>
              The cloud providers need to dive deep into potential customer
              requirements and understand the legislation that governs various
              aspects around the targeted market, specific to the client
              requirement and provide more insights on the relevant procedures
              followed.
              <br></br>Recommending deployment models and identifying the
              operational and organizational benefits would create an impact.
              Self-certifying the cloud would sell the confidence on the cloud
              solution as well as the infrastructure. Additionally explain the
              analogies around the cloud (these analogies on the cloud or the
              cloud services are unlikely to change anywhere in the near
              future).
              <br></br>
            </p>
            <p>
              <h5>BOTTOM LINE: UNDERUTILIZATION OF CLOUD SERVICES</h5>
              Cloud services are being highly underutilized due to lack of
              transparency of security, capabilities and out-of-date
              legislations. It’s time the Cloud service providers put to paper
              descriptions of the capabilities, features of their data centers
              also focusing on the legislation governing the target market to
              enable the customer learn and educate others to make right
              choices.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LifeSciences;
