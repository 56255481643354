import ServiceHeader from "../../component/ServiceHeader";
import "../PageContent.css";
import ContactUs from "../../component/contactus";
import img from "../../assets/images/database_migration_shutterstock_hanss (1).jpg";
const UtilityAndConsumption = () => {
  return (
    <div className="wrapper">
      <ServiceHeader title="UTILITY AND CONSUMPTION" />
      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div className="imgg">
            <img align="right" src={img} />
          </div>
          <div className="row middle">
            <h2>Utility And Consumption</h2>
            <p>
              <b>We Optimize your Cloud Resources for Optimal Performance</b>
              <br />
              <br />
              With the use of cloud over time, the IT environment of the cloud
              can be dragged away from its initial state of being optimized,
              resulting in a degraded security mechanism and undocumented and
              undesirable changes. The cloud performance optimization services
              offered by Teleglobal International consultants help evaluate the
              cloud environment, assess critical security loopholes to bring it
              back to maximum efficiency.
              <br />
              <br />
              Our expert cloud engineers perform an in-depth analysis of your
              entire cloud infrastructure to identify improvements that can be
              made and provide you with the best practices to operate the cloud
              optimally.
              <br />
              <br />
              <ul>
                <li>
                  performance tuning for Cloud components – Compute, Storage,
                  and Network
                </li>
                <li>Optimizing the Cloud Network and Firewall Usage</li>
                <li>Assessing vital cloud operational parameters</li>
                <li>Checking Application performance</li>
                <li>Optimizing the transactional Code</li>
                <li>Troubleshooting and Fault identification</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UtilityAndConsumption;
