import React from "react";
import ServiceHeader from "../component/ServiceHeader";
import "./PageContent.css";
import ContactUs from "../component/contactus";
import img from "../assets/images/enterpriseapplications.png";
import img1 from "../assets/images/ep1.jpg";
import img2 from "../assets/images/ep2.jpg";
import img3 from "../assets/images/ep3.jpg";
import img4 from "../assets/images/ep4.jpg";

const EnterpriseApplications = () => {
  return (
    <div className="wrapper">
      <ServiceHeader title="ENTERPRISE APPLICATIONS" />

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div class="imgg">
            <img align="right" src={img} />
          </div>
          <div className="row middle">
            {/* <h4>MIGRATIONS</h4> */}
            <p>
              SST has empowered organizations to thrive via digital technology
              and process transformation for over 2 years! Our Two centers of
              excellence bring deep expertise and experience to help clients
              empower digital enablement, optimization and transformation across
              their front, middle and back office.
            </p>
          </div>

          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img1} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="">
                <br />
                <h4>Digital</h4>
              </a>

              <p>
                Digitalizing a fraction of your enterprise operations will get
                you now here. A ubiquitous digital transformation, however, can
                change the playing field and put you way ahead of your
                competition. It will also add tangible value to your offering
                and result in user delight.
                <br />
                <br />
                We, at SST, believe in embedding digital at the heart of your
                enterprise. Whether it is re-engineering your product with the
                best UI/UX practices or bringing in AI-powered automation to the
                operations, we make your digital journey hassle-free.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img2} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="">
                <br />
                <h4>Cloud Development And Migration</h4>
              </a>

              <p>
                Transferring crucial data and other enterprise resources to the
                cloud can be intimidating to some companies, prohibiting them
                from harnessing the real power of cloud computing technologies.
                <br />
                <br />
                We sketch an appropriate cloud-first draft for organizations,
                including everything from cloud consultancy to enablement and
                the final cloud migration. We help you make a swift shift from
                on-premise servers to a secure cloud environment.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img3} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="">
                <br />
                <h4>Data</h4>
              </a>

              <p>
                The possibilities are truly endless. A business can gain a major
                competitive edge by predicting prices, demand and other critical
                growth variables efficiently. However, the enthusiasm to get
                these rich insights should not override the need to build strong
                data infrastructures. We bring all the best practices together
                to lay a solid foundation for data engineering, data science and
                data visualization.
                <br />
                <br />
                We’ve worked on various verticals – right from healthcare,
                finance and telecom to supply chain, travel and manufacturing
                and we can help you with industry specific use cases.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img4} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="">
                <br />
                <h4>Security</h4>
              </a>

              <p>
                Traditionally the term security indicated fortification and
                policing. Today it has no boundaries. It demands continuous
                assessment as well as use of analytics to protect against future
                vulnerabilities. With hybrid clouds, multiple vendors, work from
                home policies and other changes in the regulatory framework,
                securing the systems is not easy.
                <br />
                <br />
                SST believes that defense is a team effort. We have an
                experienced team of specialist engineers who use a variety of
                network monitoring tools and security systems to give you peace
                of mind.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseApplications;
