import React from "react";
import Myimage from "../../../assets/images/Hybrid-Cloud-Assessment.jpg";

import "../../../views/PageContent.css";
import ServiceHeader from "../../../component/ServiceHeader";
import ContactUs from "../../../component/contactus";

const hybridcloudassessment = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="HYBRID CLOUD ASSESSMENT" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>

        <div className="col-sm-8">
          <div className="imgg">
            <img src={Myimage} alt="security" />
          </div>

          <div className="middle">
            <h4> HYBRID CLOUD ASSESSMENT</h4>
            <br></br>
            <p>
              <b>HYBRID CLOUD</b>
              <br></br>
              What is the Hybrid Cloud Assessment?<br></br>
              The Hybrid Cloud Assessment is the fastest way to start planning
              your cloud migration strategy. 5Min-SST managed services will map
              your entire infrastructure against current pricing and
              Configuration options from the major cloud providers. Cloud
              Assessment will takes the mystery and uncertainty out of cloud
              migration and empowers your company to make the optimal choices
              based on your unique business requirement.
            </p>
            <p>
              5Min-SST will discuss with you with following main topics while
              considering the Hybrid Cloud Assessment: Current Infrastructure
              Assessment and new Hybrid Infrastructure design
            </p>
            <ul>
              <li>Technical Discovery session</li>
              <li>TCO and ROI Analysis</li>
              <li>Strategic Migration Road Map</li>
              <li>Business Case Summary</li>
              <li>Disaster Recovery</li>
              <li>Scalability</li>
              <li>Application Portfolio analysis</li>
              <li>CAPEX vs OPEX spending</li>
              <li>Improved Security</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default hybridcloudassessment;
