import React from "react";
import Myimage from "../../../assets/images/Infrastructure-assessment.png";

import "../../../views/PageContent.css";
import ServiceHeader from "../../../component/ServiceHeader";
import ContactUs from "../../../component/contactus";

const infrastructureassessment = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="INFRASTRUCTURE ASSESSMENT" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>

        <div className="col-sm-8">
          <div className="imgg">
            <img src={Myimage} alt="security" />
          </div>

          <div className="middle">
            <h4> INFRASTRUCTURE ASSESSMENT</h4>
            <br></br>
            <p>
              Moving to the cloud takes a lot of planning, and it must start
              with a process that includes organizational readiness, application
              discovery, and assessments.
            </p>
            <p>
              The goal is to obtain a gap analysis, so that you know what
              applications can be moved smoothly (retained as is) and which will
              require one of several solutions – re-factoring, re-hosting,
              re-platforming, re-purchasing, or retiring.
            </p>
            <p>
              A cloud readiness assessment is a process, involving an itemized
              list of applications and data that you want to move to the cloud
              and whether these can be moved with minimal impact on your
              business operations, as the transition proceeds. The assessment
              will tell you what steps will need to occur in order for the
              migration to proceed smoothly.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default infrastructureassessment;
