import React from "react";
import Myimage from "../assets/images/Security-Compliance-Assessment.jpg";
import Myimage1 from "../assets/images/Security-of-Cloud-Computing.jpg";
import Myimage2 from "../assets/images/Cloud-Assessment.png";

import "../views/PageContent.css";
import ServiceHeader from "../component/ServiceHeader";
import ContactUs from "../component/contactus";

const security = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="SECURITY COMPLIANCE AND ASSESSMENTS" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>

        <div className="col-sm-8">
          <div className="imgg">
            <img src={Myimage} alt="security" />
          </div>

          <div className="row middle">
            <h2>Security ,Compliance And Assessment</h2>
            <p>
              CSA provides a security guidance framework that can be used to
              help assess the security of cloud-based business processes, and
              ensure the continued confidentiality, integrity, and availability
              of those processes. As organizations across the globe moved to
              cloud computing, the idea of an on-premise, network perimeter to
              protect data, became no longer relevant.
            </p>
          </div>
          <div className="IMG">
            <div className=" col-sm-3">
              <img src={Myimage1} alt="security1" className="Myimg" />
            </div>
            <div className=" col-sm-9 rightcont">
              <br />{" "}
              <a href="/security" className="fontcolor">
                <br />
                <h4>Security</h4>
              </a>
              <p>
                {" "}
                <b>Cloud security</b> is the protection of data stored online
                via cloud computing platforms from theft, leakage, and deletion.
                Methods of providing cloud security include firewalls,
                penetration testing, obfuscation, tokenization, virtual private
                networks (VPN), and avoiding public internet connections.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className=" col-sm-3">
              <img
                align="center"
                src={Myimage2}
                alt="security2"
                className="Myimg"
              />
            </div>
            <div className=" col-sm-9 rightcont">
              <br />
              <a href="/assessment" className="fontcolor">
                <br />
                <h4>Assessment</h4>
              </a>

              <p>
                A <b>cloud</b> readiness <b>assessment </b>is a process where an
                organization looks at its resources and IT environment and
                determines if it is capable of migrating to the <b>cloud</b>. …
                An organization that has ensured it is ready to migrate and has
                formulated a plan on how to do so can reduce the time and money
                it takes to migrate.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default security;
