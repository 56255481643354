import React from "react";
import Myimage from "../../assets/images/Media-and-Entertainment.jpg";

import "../../views/PageContent.css";
import ContactUs from "../../component/contactus";

import ServiceHeader from "../../component/ServiceHeader";
const MediaEntertainment = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="MEDIA AND ENTERTAINMENT" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div className="imgg">
            <img src={Myimage} alt="legal" />
          </div>

          <div className="middle">
            <p>
              <h5>
                THE ADVANTAGES OF CLOUD COMPUTING FOR MEDIA & ENTERTAINMENT
              </h5>
              How do businesses use cloud computing? In this section we look at
              how the advantages of using cloud storage are helping the M&E
              sector face four of its most important challenges.
              <br></br>
            </p>
            <p>
              <h5>DEMANDING CONSUMERS, UNPREDICTABLE DEMAND</h5>
              Today’s consumers of media and entertainment content expect
              flexibility and choices. In addition, consumer demand is
              unpredictable, with spikes and surges in viewership that have to
              be dealt with in real time. The on-demand characteristics of cloud
              computing provide the levels of scalability and flexibility that
              the M&E sector needs to cost-effectively meet volatile demand,
              automatically spinning servers up and down as demand ebbs and
              flows.
              <br></br>
            </p>
            <p>
              <h5>
                AGILITY, SPEEDIER TIME TO MARKET, CONTINUOUS EXPANSION OF
                CONTENT
              </h5>
              In order to stay competitive, content creators in the M&E sector
              are under pressure to produce and/or distribute original content
              more frequently and faster. Without the cloud it would be close to
              impossible to store, manage, and deliver these huge quantities of
              digital content in an agile, dynamic and cost-effective manner.
              Hybrid and/or multicloud deployments can provide an even greater
              measure of flexibility, allowing workloads to be shifted
              seamlessly across public and private infrastructures.
              <br></br>
            </p>
            <p>
              <h5>COST BENEFITS OF CLOUD COMPUTING</h5>
              Traditional M&E distribution companies such as cable or broadcast
              television are being challenged by new trends in television
              broadcasting and agile, low-cost OTT (Over the Top) companies that
              sell and/or deliver streaming audio, video and other media
              directly to consumers over the Internet. Other factors that are
              challenging the bottom lines of the content-oriented M&E companies
              are the rising costs of content licensing, as well as shortened
              technology lifecycles.
              <br></br>Cloud computing in the media and entertainment industry
              offers opportunities to contain technology and storage costs in
              order to balance the rising costs in other areas. By shifting from
              a CAPEX model to the cloud’s OPEX model, for example, M&E
              companies can reduce their sunk costs in storage and delivery
              technologies and infrastructures. Also, using the different tiers
              of cloud storage SLAs for their hot vs. cold digital assets can
              reduce storage costs considerably for M&E companies.
              <br></br>
            </p>
            <p>
              <h5>HIGH PERFORMANCE, MINIMAL DELAYS/DOWNTIME</h5>
              High streaming performance, with minimal delays and downtime, is
              critical both in terms of user experience as well as advertiser
              expectations. A six-second delay in streaming an ad for a hit
              show, for example, can cost a media company hundreds of thousands
              of dollars that they have to give back to the advertiser.
              <br></br>The cloud provides ample opportunities for dispersed and
              redundant
              <br></br>
              Architectures that support high availability and high-performance
              SLAs, such as multi-region, multicloud, and hybrid deployments.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaEntertainment;
