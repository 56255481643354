import ServiceHeader from "../../component/ServiceHeader";
import "../PageContent.css";
import ContactUs from "../../component/contactus";
import img from "../../assets/images/img2.jpg";

const AzureMigrations = () => {
  return (
    <div className="wrapper">
      <ServiceHeader title="AZURE MIGRATIONS" />

      {/* <div className="row4">h</div> */}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div className="imgg">
            <img align="right" src={img} />
          </div>
          <div className="row middle">
            <h2>AZURE MIGRATIONS</h2>
            <p>
              <b>Our Cloud Migration Services</b>
              <p>
                5Minute-SST has helped 400+ customers accelerate migration,
                avoid common pitfalls, and meet security and compliance
                requirements.
              </p>

              <p>
                <b>Step 1: Assess</b>
                <p>
                  Our Cloud Architects will analyze your existing architecture,
                  understand your business needs, and perform a TCO Analysis.
                </p>
                <ul>
                  <li>
                    Understand your team’s goals and technical limitations
                  </li>

                  <li>TCO Analysis</li>

                  <li>Identify security and compliance goals</li>

                  <li>Choose the right cloud platform</li>
                </ul>
              </p>
              <p>
                <b>Step 2: Design & Build</b>
                <p>
                  We’ll design and build a custom environment that meets your
                  security, compliance, agility, and cost-efficiency
                  requirements.
                </p>
                <ul>
                  <li>Design secure, compliant reference architecture</li>

                  <li>Develop and build a Minimum Viable Cloud</li>

                  <li>Implement security and compliance control</li>
                </ul>
              </p>
              <p>
                <b>Step 3: Migrate</b>

                <p>
                  Get the keys to a production-ready cloud environment and go
                  live on the cloud.
                </p>
                <ul>
                  <li>
                    Choose the right cloud-native or 3rd party tool for data
                    migration
                  </li>

                  <li>
                    Migrate data from on-premises database to the cloud-based
                    database
                  </li>
                  <li>Executive presentation and hand-off</li>
                </ul>
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AzureMigrations;
