import ServiceHeader from "../../component/ServiceHeader";
import "../PageContent.css";
import ContactUs from "../../component/contactus";
import img from "../../assets/images/patch-management-1.png";
const AwsManagedSer = () => {
  return (
    <div className="wrapper">
      <ServiceHeader title="AWS MANAGED SERVICE" />
      <div className="row mp">
        <div className="col-sm-4 ">
          <img align="center" src={img} className="awsmanagedservice" />
        </div>
        <div className="col-sm-8 ">
          <h1>OUR MANAGED AWS SERVICE</h1>
          <br />
          <br />
          <p>
            We combine 24×7 expert support with our world-class management
            platform, to help you achieve operational excellence on AWS. With
            our Managed AWS Service, you can focus on revenue-generating tasks
            and rely on our Certified AWS team to manage your infrastructure.
          </p>
          <ul>
            <li>24×7 Expert Support</li>
            <li>
              Get around-the-clock monitoring and incident response from a team
              of certified, on-shore (never outsourced) AWS engineers
            </li>
            <li>Cloud Management Platform</li>
            <li>
              Get consolidated billing, ticketing, security, and budget
              management in one easy-to-use, central portal
            </li>
            <li>Security + Compliance</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default AwsManagedSer;
