import React from "react";
import "./PageContent.css";
import img from "../assets/images/manageCimg1.png";
import img2 from "../assets/images/mcimg2.jpg";
import img3 from "../assets/images/mc-photo-2.jpg";
import img4 from "../assets/images/mc-photo-3.jpg";
import img5 from "../assets/images/mc-photo-4.jpg";
import img6 from "../assets/images/mc-photo-5.jpg";
import img7 from "../assets/images/mc-photo-6.jpg";
import ServiceHeader from "../component/ServiceHeader";
import ContactUs from "../component/contactus";
const ManageCloud = () => {
  return (
    <div className="wrapper">
      <ServiceHeader title="MANAGE CLOUD" />
      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div className="imgg">
            <img align="right" src={img} />
          </div>
          <div className="row middle">
            <h2>Google Cloud Platform Managed Services</h2>
            <p>Predictable, Proven Solutions from GCP Experts</p>

            <p>
              5M-SST is a leader in managing Google Cloud Platform environments
              at any scale.
            </p>

            <p>
              We provide you with a team of certified GCP experts to take
              operational tasks off your plate. As a Google Cloud Platform
              Partner, our services provide leading-edge management and
              optimization practices to ensure security, reduce Google Cloud
              costs, and improve availability.
            </p>

            <p>
              By acting as an extension of your team, whether in a full or
              partial managed services capacity, 5M-SST opens the door to
              innovation by delivering a dependable environment for your most
              critical business functions.
            </p>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img2} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a href="/awsmigrations" className="fontcolor">
                <br />
                <h4>AWS Database Migration Service</h4>
              </a>

              <p>
                Migrate your databases to AWS with minimal downtime More than
                350,000 databases migrated using AWS Database Migration Service
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img3} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a href="" className="fontcolor">
                <br />
                <h4>Dedicated Experts</h4>
              </a>

              <p>
                Back your team with 5M-SST Google Cloud certified experts as
                well as a dedicated Client Success Manager.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img4} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a href="" className="fontcolor">
                <br />
                <h4>Continuous Optimization</h4>
              </a>

              <p>
                Take the complexity out of your cloud environment through
                continuous, expert-driven improvements to your GCP environment.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img5} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a href="" className="fontcolor">
                <br />
                <h4>Cloud Cost Optimization</h4>
              </a>
              <p>
                Simplify your billing with predictable, affordable cloud costs
                for your single or multi-cloud environment.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img6} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a href="" className="fontcolor">
                <br />
                <h4>Multi-Cloud Masters</h4>
              </a>

              <p>
                Back your multi-cloud environment with proven expertise. Qwinix
                holds partner-status with each of the public clouds (AWS, GCP,
                and Azure).
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img7} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a href="" className="fontcolor">
                <br />
                <h4>Security And Compliance</h4>
              </a>

              <p>
                Enjoy peace of mind knowing our team will detect, respond, and
                prevent gaps in cloud security and compliance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageCloud;
