const sidebarRoutes = {
  homeNavItems: [
    {
      name: "Development & Testing Services",
      href: "/developmenttestingservices",
    },
    { name: "About Us", href: "/AboutUs" },
    { name: "Partners", href: "/partners" },
    { name: "Contact Us", href: "/ContactUs" },
  ],
  genaiNavItems: [
    { name: "Helthcare", href: "/healthcareai" },
    { name: "Autoscribe", href: "/autoscribe" },
    { name: "Insurance", href: "/insurance" },
    { name: "Utility", href: "/utility" },
    { name: "Product Automation", href: "/productautomation" },
    { name: "Financial", href: "/financial" },
  ],
  manageCloudNavItems: [
    { name: "AWS Managed Services", href: "/awsmanagedser" },
    { name: "Azure Managed Services", href: "/azuremanagedser" },
    { name: "GCP Managed Services", href: "/gcpmanagedser" },
    { name: "Cloud Optimization", href: "/cloudoptimization" },
    { name: "Standard Services", href: "/standardedservices" },
    { name: "Ondemand Services", href: "/ondemandservices" },
  ],

  migrationNavItems: [
    { name: "AWS Migration", href: "/awsmigrations" },
    { name: "Azure Migration", href: "/azuremigrations" },
    { name: "GCP Migration", href: "/gcpmigrations" },
    { name: "Hybrid Cloud", href: "/hybridcloud" },
    { name: "Disaster Recovery", href: "/disasterrecovery" },
    { name: "Database Migration", href: "/databasemigrations" },
    {
      name: "Utilization And Consumption",
      href: "/utilityandconsuption",
    },
  ],
  securityComplianceNavItems: [
    {
      name: "Security",
      href: "/security",
      subRoutes: [
        {
          name: "Cloud Security",
          href: "/cloudsecurity",
        },
        { name: "Managed Security", href: "/managedsecurity" },
        { name: "Azure Security", href: "/azuresecurity" },
        { name: "AWS Security", href: "/awssecurity" },
        { name: "GCP Security", href: "/gcpsecurity" },
      ],
    },
    {
      name: "Assessments",
      href: "/assessment",
      subRoutes: [
        {
          name: "Infrastructure Assessment",
          href: "/InfrastructureAssessment",
        },
        { name: "Data Centers Assessment", href: "/DataCenterAssessment" },
        { name: "Hybrid Cloud Assessment", href: "/hybridcloudassessment" },
        { name: "Application Assessment", href: "/appassessment" },
        { name: "Database Assessment", href: "/databaseassessment" },
        { name: "Security Assessment", href: "/SecurityAssessment" },
      ],
    },
  ],

  industryNavItems: [
    { name: "Overview", href: "/overview" },
    { name: "Financial Services", href: "/financialServices" },
    { name: "Healthcare", href: "/healthcare" },
    { name: "Legal", href: "/legal" },
    { name: "Life Sciences", href: "/lifesciences" },
    {
      name: "Media and Entertainment",
      href: "/mediaandentertainment",
    },
    { name: "Public Sector", href: "/publicsector" },
    { name: "Retail", href: "/retail" },
    {
      name: "Small & Medium Business",
      href: "/smallandmediumbusiness",
    },
  ],

  developmentTestingNavItems: [
    {
      name: "Mobile Application Development",
      href: "/developmenttestingservices",
    },
    {
      name: "Web Application Development",
      href: "/webapplicationdevelopment",
    },
    { name: "Quality Assurance", href: "/qualityassurance" },
  ],

  aboutUsNavItems: [
    { name: "Company", href: "/company" },
    { name: "Leadership Team", href: "/leadershipteams" },
    { name: "Careers", href: "/careers" },
    {name: "Policy", href:"/policy"}
  ],
};

export default sidebarRoutes;
