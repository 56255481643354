import React from "react";
import "./Home.css";
import serviceimage1 from "../assets/images/people.webp";
import serviceimage2 from "../assets/images/bulbimage.webp";
import serviceimage3 from "../assets/images/security.webp";
import serviceimage4 from "../assets/images/industry.webp";
import serviceimage5 from "../assets/images/About.webp";
import serviceimage6 from "../assets/images/peoples.webp";
import serviceAI from "../assets/images/Frame 3.jpg";
import serviceContactForm from "../component/ContactForm";
import Carousel from "react-bootstrap/Carousel";
import image1 from "../assets/AI images/Frame 5.jpg";
import image2 from "../assets/AI images/Frame 8.jpg";
import image3 from "../assets/AI images/Frame 4.jpg";
import ContactForm from "../component/ContactForm";

const Home = () => {
  return (
    <>
      <div className="container">
        <div className="w-100 py-2">
          <Carousel>
            <Carousel.Item>
              <img className="d-block w-100 " src={image1} alt="First slide" />
              <Carousel.Caption>
                {/* <h3>Gen AI</h3> */}
                <h4 className="service-heading">
                  Explore the future with our AI services, where intelligent
                  algorithms and data networks empower innovation and efficiency
                  for your business needs.
                </h4>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 h-10"
                src={image2}
                alt="Second slide"
                // style={{ height: "10% !important" }}
              />
              <Carousel.Caption>
                {/* <h3>Gen AI</h3> */}
                <h4 className="service-heading">
                  Enhance your operations with our AI-driven automation
                  services, designed to elevate efficiency and foster innovation
                  in your business landscape
                </h4>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 h-10"
                src={image3}
                alt="Second slide"
                // style={{ height: "10% !important" }}
              />
              <Carousel.Caption>
                {/* <h3>Gen AI</h3> */}
                <h4 className="service-heading">
                  Empower your enterprise with our advanced AI solutions,
                  holding the key to unlocking new possibilities and steering
                  your business towards a smarter future.
                </h4>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          <div>
            <h2 className="text">
              WE MANAGE ENTIRE{" "}
              <span className="span-text">CLOUD MANAGEMENT LIFECYCLE</span>
            </h2>
            <p className="p-text">
              SST Managed Services simplifies management of the new
              infrastructure &amp; platform with comprehensive
              <br />
              Managed Services for Azure, thereby expediting business
              transformation and enabling innovation.
            </p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card">
                <img src={serviceAI} alt="AI" className="img-style" />
                <div className="card-body">
                  <h5 className="href-h5">
                    <a href="genai" title="Gen AI" className="a-line">
                      Gen AI
                    </a>
                  </h5>
                  <ul className="list-unstyled">
                    <li>Automatication</li>
                    <li>Langchain</li>
                    <li>OpenAI</li>
                    <li>Azure OpenAI</li>
                  </ul>
                  <h6>
                    <a href="genai" title="Manage Cloud" className="a-line">
                      Tell Me More
                    </a>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card">
                <img
                  src={serviceimage1}
                  alt="3 people images"
                  className="img-style"
                />
                <div className="card-body">
                  <h5 className="href-h5">
                    <a
                      href="manageCloud"
                      title="MANAGE CLOUD"
                      className="a-line"
                    >
                      MANAGE CLOUD
                    </a>
                  </h5>
                  <ul className="list-unstyled">
                    <li>Environment Migration</li>
                    <li>Automation Tools</li>
                    <li>Migration & Launch</li>
                    <li>Train & Document</li>
                  </ul>
                  <h6>
                    <a
                      href="manageCloud"
                      title="Manage Cloud"
                      className="a-line"
                    >
                      Tell Me More
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <img src={serviceimage2} alt="blub" className="img-style" />
                <div className="card-body">
                  <h5 className="href-h5">
                    <a href="migrations" title="Migration" className="a-line">
                      MIGRATIONS
                    </a>
                  </h5>
                  <ul className="list-unstyled">
                    <li> Infrastructure Management</li>
                    <li>Certified Expert Support</li>
                    <li>Customized Solutions</li>
                    <li>Reports & Monitoring</li>
                  </ul>
                  <h6>
                    <a
                      href="migrations"
                      title="Manage Cloud"
                      className="a-line"
                    >
                      Tell Me More
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card">
                <img src={serviceimage4} alt="blub" className="img-style"></img>
                <div className="card-body">
                  <h5 className="href-h5">
                    <a href="industry" title="Industry" className="a-line">
                      Industry
                    </a>
                  </h5>
                  <h6>Industry</h6>
                  <h6>
                    <a href="industry" title="Manage Cloud" className="a-line">
                      Tell Me More
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <img
                  src={serviceimage5}
                  alt="blub2"
                  className="img-style"
                ></img>
                <div className="card-body">
                  <h5 className="href-h5">
                    <a href="AboutUs" title="About Us" className="a-line">
                      About Us
                    </a>
                  </h5>
                  <h6>About Us</h6>
                  <h6>
                    <a href="AboutUs" title="Manage Cloud" className="a-line">
                      Tell Me More
                    </a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <img
                  src={serviceimage6}
                  alt="3 people images"
                  className="img-style"
                ></img>
                <div className="card-body">
                  <h5 className="href-h5">
                    <a href="ContactUs" title="Contact us" className="a-line">
                      Contact Us
                    </a>
                  </h5>
                  <h6>Contact Us</h6>
                  <h6>
                    <a href="ContactUs" title="Manage Cloud" className="a-line">
                      Tell Me More
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="screen-reader-response">
              <p role="status" aria-live="polite" aria-atomic="true"></p>
              <ul></ul>
            </div>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail"
                        placeholder="Email"
                      ></input>
                    </div>
                    <div className="form-group col-md-12">
                      <input
                        type="password"
                        className="form-control"
                        id="inputPassword"
                        placeholder="Password"
                      ></input>
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <select id="inputState" className="form-control">
                      <option selected>Select One</option>
                      <option>Manage Existing Cloud</option>
                      <option>Migrate to the Cloud</option>
                      <option>Consulting Advisory</option>
                      <option>Security Assesment & Compilance Related</option>
                      <option>Other</option>
                    </select>
                  </div>
                  <div className="form-group col-md-12">
                    <input
                      type="phone number"
                      className="form-control"
                      id="phonenumber"
                      placeholder="Your Phone number"
                    ></input>
                  </div>
                  <button>Submit Now</button>
                </form>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* </div> */}
      <ContactForm />
    </>
  );
};

export default Home;
