import React from "react";
import Myimage from "../../assets/images/legal.jpg";

import "../../views/PageContent.css";
import ContactUs from "../../component/contactus";

import ServiceHeader from "../../component/ServiceHeader";
const Legal = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="LEGAL" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div className="imgg">
            <img src={Myimage} alt="legal" />
          </div>

          <div className="middle">
            <h4>
              LAW FIRM CLOUD MIGRATION: IN THE NEXT FIVE YEARS, MOST LAW FIRMS
              WILL BE USING CLOUD FOR CRITICAL SYSTEMS
            </h4>
            <p>
              In the next five years or so, we think the vast majority of firms
              will embrace the cloud, from reputable solution providers, without
              a second thought.
              <br></br>That’s a projection we’ve made in a Legal Business
              Report, titled The Next Five Years: Future of Legal Technology.
              The cloud is one of three key legal trends in the report that we
              forecast will have a considerable influence on the business of law
              in the near term.
            </p>
            At first glance, the data supports this viewpoint, and it doesn’t
            seem too audacious. Consider the following statistics:
            <ul>
              <li>
                78% of law firms currently store client data in the cloud;
                another 8% have plans to do so in the near future, according to
                a 2019 survey by Fish & Richardson and ILTA; the survey polled
                members of ILTA.
              </li>
              <li>
                74% of law firms are at least “somewhat” or “slightly”
                cloud-based – and another 12% are “mostly” or “completely”
                cloud-based according to the 2019 Business of Law and Legal
                Technology Survey, an annual survey of allied legal
                professionals we facilitate;
              </li>
              <li>
                In 2018, law firm cloud usage grew to about 55%, according to
                the 2018 Legal Technology Survey Report by the ABA which polls
                lawyers; just 9% cited client concerns as a barrier to cloud
                adoption;
              </li>
              <li>
                The ABA survey also found that 31% of lawyers believe the cloud
                services can provide greater security than their respective
                firms; in addition, “lawyers are becoming more familiar with
                cloud technologies and are attracted by anytime, anywhere
                access, low cost of entry, predictable monthly expenses, and
                robust data backup”; and 69% of respondents indicated their firm
                would be more likely to adopt cloud solutions over the next 12
                months – a six-point leap from the year prior, according to the
                2018 Legal Technology Survey by ILTA.
              </li>
              <li>
                Lastly, in our 2019 Business of Law and Legal Technology Survey,
                we found that one of the top challenges facing law firms –
                cybersecurity – dropped 16 points, from 33% to 17% in just one
                year. The study suggests firms are more comfortable with the
                subject matter, as cybersecurity fell from second to seventh
                place in the 2019 rankings of top challenges facing law firms.
              </li>
            </ul>
            <p>
              That all sounds quite convincing, but as it is with all things
              legal and technology, nuance matters. It’s one thing to say your
              firm will use more cloud services – everyone needs to share a file
              that’s too large for email sometimes – but a divergence emerges
              when asked about critical systems.
              <br></br>
            </p>
            <p>
              For example, in our survey – the 2019 Business of Law and Legal
              Technology Survey – we drilled down on that idea. When we asked
              respondents about moving critical systems to the cloud, the
              answers were fairly evenly split in a three-way tie among, yes,
              no, and probably never.
            </p>
            <p>
              More specifically, we asked respondents if they thought their law
              firm practice management system, the crown jewels of a law firm,
              would move to the cloud, the answers were far less convincing.
            </p>
            <p>
              Here’s how those stacked up:
              <ul>
                <li>
                  34% said this system is already hosted in the cloud or headed
                  that way within two years;
                </li>
                <li>
                  37% also said this would not happen in the foreseeable future;
                </li>
                <li>1% said it would never happen; and</li>
                <li>28% were unsure.</li>
              </ul>
            </p>
            <p>
              This, of course, seems much less certain, so will we stand by our
              prediction? Indeed, we will, and we want to be clear that we don’t
              just mean law firms are going to be a little more cloud friendly.
              Instead, we believe in the next five years, most law firms will be
              hosting critical systems on servers that belong to, and are
              managed by, trusted partners.
            </p>
            <br></br>
            <p>
              In other words, the vast majority of firms will be predominately
              cloud-based.
              <ol>
                <li>
                  <b>
                    Most clients work for businesses that rely on the cloud.
                  </b>
                  <br></br>Clients were a big part of the original barrier to
                  law firm cloud adoption. Today most of them work for
                  businesses that host sensitive data – customer, financial, and
                  trade secret – in the cloud. The ABA report cited above also
                  references credible third-party data suggesting that in 2018,
                  a whopping 96% of businesses reported using cloud services.
                  Cybersecurity was among the top concerns for law firms in
                  2018, according to the 2019 Business of Law and Legal
                  Technology Survey, but has since dropped drastically. Security
                  is still an important issue, however for many firms it’s no
                  longer a new issue and is something they’re able to tackle
                  with the help of security expertise from cloud providers like
                  Amazon, Microsoft, or Google.
                </li>
                <li>
                  {" "}
                  <b>Clients want improved collaboration with their firms.</b>
                  <br></br>Clients have been pressuring their law firm partners
                  for greater transparency and tighter collaboration for years –
                  and this will only continue to increase. The level of
                  collaboration that clients want can only be supported with
                  cloud-based technology. This will be a dramatic turn of
                  events, where clients once objected to law firm cloud
                  adoption, to proactively asking for it.
                </li>
                <li>
                  {" "}
                  <b>Many law firms are already using the cloud.</b>
                  <br></br>Whether the lawyers realize it or not, many of their
                  firms have already begun to use the cloud with hosted email
                  and document management systems (DMS). These communications
                  and collaboration tools are, in fact, critical systems – most
                  law firms couldn’t operate without them. Some technologists
                  suggest those two systems are a roadmap for the great law firm
                  cloud migration. “Start with email, then the document
                  management system (DMS) and finally practice management,” says
                  Craig Bayer, a legal technology consultant with Optiable, LLC.
                </li>
                <li>
                  <b>
                    The cloud is catching up with on-prem features and
                    functions.
                  </b>
                  <br></br>Some law firms objected to the cloud because most
                  on-premise software, with decades of development, had far more
                  robust features and functions. This enabled firms to
                  personalize, customize, and configure the software to their
                  liking – and many cloud products couldn’t match this
                  flexibility. Today, that’s no longer true. Many cloud products
                  for law firms have closed, or are closing, the gap rapidly.
                  Additionally, the cost of keeping up a server has proved
                  cumbersome for many firms – and not just the server needs to
                  be kept current. The operating systems, tools, and patch
                  releases can become tedious. The attractiveness with cloud
                  offerings is that all upkeep (server and operating system) is
                  often included in the price.
                </li>
                <li>
                  <b> Single tenancy: public versus private cloud.</b>
                  <br></br>Like some of the previous objections, some firms were
                  concerned about co-locating data in a public cloud. This
                  included the physical geographic location of the data and
                  associated jurisdiction. However, as leading legal technology
                  providers have poured research and development investment into
                  cloud products, they created controls to address the fears of
                  multi-tenancy.
                </li>
              </ol>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legal;
