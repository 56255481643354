import React from "react";
import ContactForm from "../component/ContactForm";
import ServiceHeader from "../component/ServiceHeader";
import Youtube from "../assets/images/yt.png";
import Tweet from "../assets/images/tweet.png";
import Linkedin from "../assets/images/Linkedin.png";
import Instagram from "../assets/images/Insta.png";
import Facebook from "../assets/images/facebook.png";
import Web from "../assets/images/Web.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import "./ContactUs.css";

const ContactUs = () => {
  return (
    <div>
      <ServiceHeader title="CONTACT US" />
      <div className="container">
        <div className="row maps">
          <div className="col-sm-1 "></div>
          <div className="col-sm-5 iframedesign">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3036.2878886386957!2d-74.4971829!3d40.446764699999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3c50040bdaaab%3A0xa57962cd4c1d7be5!2sSST(Shri%20Sai%20Tech%20LLC)!5e0!3m2!1sen!2sin!4v1719373973979!5m2!1sen!2sin"
              height="350px"
              title="Google Map - New Jersey Location"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>

            <div>
              <p className="location-text">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  style={{ color: "blue", height: "18px", width: "18px" }}
                />
                &nbsp;&nbsp;<b>US Location:</b>
              </p>
              <p className="address-text">
                <b>New Jersey:</b>
                <br />
                2100 US Highway 1 North North Brunswick NJ 08902
                <br />
                USA
                <br />
              </p>
              <FontAwesomeIcon
                icon={faPhone}
                style={{ color: "blue", marginLeft: "30px" }}
              />
              &nbsp;&nbsp;732-915-3055
            </div>
          </div>
          <div className="col-sm-1"></div>

          <div className="col-sm-5 iframedesign">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3801.5799308920414!2d75.92801897463306!3d17.670043694601972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc5db0a1188be13%3A0x1c3c72af1dcdea02!2sSST%20India!5e0!3m2!1sen!2sin!4v1718100124631!5m2!1sen!2sin"
              height="350px"
              title="Google Map - Solapur Location"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>

            <div>
              <p className="location-text">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  style={{ color: "blue", height: "18px", width: "18px" }}
                />
                &nbsp;&nbsp;<b>India Location:</b>
              </p>
              <p className="address-text">
                <b>Solapur:</b>
                <br />
                Plot No.C-29 Nagnath Laghu Udyog Sahakari Sansrtha,
                <br />
                Akkalkot Road MIDC, Solapur, Maharashtra-413006
                <br />
              </p>
              <FontAwesomeIcon
                icon={faPhone}
                style={{ color: "blue", marginLeft: "30px" }}
              />{" "}
              &nbsp;&nbsp;+919370588999 / +917058478425
            </div>
          </div>
        </div>
        <br></br>
        <br></br>

        <div className="row social-media">
          <div className="col-sm-1"></div>
          <div className="col-sm-5">
            <p>
              <img src={Web} alt="sst-weblog" />
              Website: <a href="http://sstus.net/">http://sstus.net/</a>
            </p>
            <p>
              <img src={Facebook} alt="facebook logo" />
              Facebook:{" "}
              <a href="https://www.facebook.com/shri.saitech/">
                https://www.facebook.com/shri.saitech/
              </a>
            </p>
            <p>
              <img src={Instagram} alt="instagram logo" />
              Instagram:{" "}
              <a href="https://instagram.com/shri_saitech?igshid=10ur0rtbu09ay">
                https://instagram.com/shri_saitech?igshid=10ur0rtbu09ay
              </a>
            </p>
          </div>
          <div className="col-sm-1"></div>
          <div className="col-sm-5">
            <p>
              <img src={Linkedin} alt="linkedin logo" />
              LinkedIn:{" "}
              <a href="https://www.linkedin.com/in/shrisai-technology-848073208">
                https://www.linkedin.com/in/shrisai-technology-848073208
              </a>
            </p>
            <p>
              <img src={Tweet} alt="twitter logo" />
              Twitter:{" "}
              <a href="https://twitter.com/ShriSaiTech1">
                https://twitter.com/ShriSaiTech1
              </a>
            </p>
            <p>
              <img src={Youtube} alt="youtube logo" />
              YouTube:{" "}
              <a href="https://myaccount.google.com/u/0/">
                https://myaccount.google.com/u/0/
              </a>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 contact-form-container">
            <ContactForm />
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
