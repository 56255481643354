import React from "react";
import Myimage from "../../assets/images/Security-of-Cloud-Computing.jpg";
import Myimage1 from "../../assets/images/cloud-security-1.jpg";
import Myimage2 from "../../assets/images/Managed-Security.png";
import Myimage3 from "../../assets/images/Azure-Security.jpg";
import Myimage4 from "../../assets/images/AWS-Security.png";
import Myimage5 from "../../assets/images/GCP-Mi-new.jpg";

import "../../views/PageContent.css";
import ServiceHeader from "../../component/ServiceHeader";
import ContactUs from "../../component/contactus";

const security = () => {
  return (
    <div className="wrapper">
      <ServiceHeader title="SECURITY" />

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>

        <div className="col-sm-8 rightcont">
          <div className="imgg">
            <img src={Myimage}  alt="security" />
          </div>

          <div className="row middle">
            <h3>Security</h3>
            <p>
              <b>Cloud security </b>is the protection of data stored online via{" "}
              <b>cloud</b> computing platforms from theft, leakage, and
              deletion. Methods of providing <b>cloud security </b>include
              firewalls, penetration testing, obfuscation, tokenization, virtual
              private networks (VPN), and avoiding public internet connections.
            </p>
          </div>
          <div className="IMG">
            <div className=" col-sm-3">
              <img src={Myimage1} alt="security2" className="Myimg" />
            </div>
            <div className=" col-sm-9 rightcont">
              <p>
                <br />{" "}
                <a href="/cloudsecurity" className="fontcolor">
                  <br />
                  <h4>Cloud Security</h4>
                </a>
                <br />
                Cloud security, also known as cloud computing security, consists
                of a set of policies, controls, procedures, and technologies
                that work together to protect cloud-based systems, data, and
                infrastructure{" "}
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className=" col-sm-3">
              <img
                align="center"
                src={Myimage2}
                alt="security2"
                className="Myimg"
              />
            </div>
            <div className=" col-sm-9 rightcont">
              <p>
                <br />
                <a href="/managedsecurity" className="fontcolor">
                  <br />
                  <h4>Managed Security</h4>
                </a>
                <br />
                Elite cyber threat intelligence combined with security expertise
                protects devices and data and provides continuous security
                monitoring and operational administration of managed devices to
                safeguard investments and meet compliance regulations.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className=" col-sm-3">
              <img
                align="center"
                src={Myimage3}
                alt="security2"
                className="Myimg"
              />
            </div>
            <div className=" col-sm-9 rightcont">
              <p>
                <br />
                <a href="/azuresecurity" className="fontcolor">
                  {" "}
                  <br />
                  <h4>Azure Security</h4>
                </a>
                <br />
                Protect data, apps, and infrastructure quickly with built-in
                security services in Azure that include unparalleled security
                intelligence to help identify rapidly evolving threats early—so
                you can respond quickly. Implement a layered, defense-in-depth
                strategy across identity, data, hosts, and networks.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className=" col-sm-3">
              <img
                align="center"
                src={Myimage4}
                alt="security2"
                className="Myimg"
              />
            </div>
            <div className=" col-sm-9 rightcont">
              <p>
                <br />
                <a href="/awssecurity" className="fontcolor">
                  <br />
                  <h4>AWS Security</h4>{" "}
                </a>
                <br />
                AWS provides services that help you protect your data, accounts,
                and workloads from unauthorized access. AWS data protection
                services provide encryption and key management and threat
                detection that continuously monitors and protects your accounts
                and workloads.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className=" col-sm-3">
              <img
                align="center"
                src={Myimage5}
                alt="security2"
                className="Myimg"
              />
            </div>
            <div className=" col-sm-9 rightcont">
              <p>
                <br />
                <a href="/gcpsecurity" className="fontcolor">
                  <br />
                  <h4>GCP Security</h4>
                </a>
                <br />
                Google Cloud Platform (GCP), offered by Google, is a suite of
                cloud computing services that runs on the same infrastructure
                that Google uses internally for its end-user products, such as
                Google Search, Gmail, file storage, and YouTube.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default security;
