import ServiceHeader from "../../component/ServiceHeader";
import "../PageContent.css";
import ContactUs from "../../component/contactus";
import img from "../../assets/images/standartservices.png";
const StandardServices = () => {
  return (
    <div className="wrapper">
      <ServiceHeader title="CLOUD OPTIMIZATION" />

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div className="imgg">
            <img align="right" src={img} />
          </div>
          <div className="row middle">
            <h2>STANDARD SERVICES</h2>
            <p>
              <p>
                5M-SST provides 24×7 eyes on glass infrastructure monitoring for
                the client’s AWS/Azure Infrastructure. 5M-SST has the best in
                class tool stack and 24×7 technical support desk.
              </p>
              <p>
                5M-SST monitors and manages your Cloud Infrastructure on a 24×7
                basis, with our Managed Services, CLIENT will gain instant
                access to 5M-SST’s complete AWS/Azure support team. 5M-SST’s
                AWS/Azure Monitoring and Management services ensure that the
                CLIENT‘s infrastructure and data are operating at peak
                performance all day, every day. 5M-SST will monitor the CLIENT’s
                complete AWS/Azure environment and communicate as frequently as
                the CLIENT would like, through ongoing notifications and alerts.
                Below are Five minutes SST Managed Services Offerings made to
                Client;
              </p>
              <ul>
                <li>24×7 Proactive Infrastructure Event, Performance, and</li>
                <li>Availability Monitoring Monthly Infrastructure Patch</li>
                <li>
                  Management Services Identity & Access Management Services
                </li>
                <li>Daily/Weekly/Monthly Backup Services</li>
                <li>
                  24×7 AWS/Azure Network Event, Performance, and Availability
                  Monitoring
                </li>
                <li>Backup and Restore Services</li>
                <li>Database Management Services </li>
                <li>Network Management Services</li>{" "}
                <li>
                  Advance Security Management Services (IDS/IPS, DoS, DDoS){" "}
                </li>
                <li>5M-SST Managed Services – Operations Support</li>
                <li>
                  {" "}
                  24×7 Technical Support Desk for users via Phone, Email, and
                  Web
                </li>{" "}
                <li>L1 Support – Day to Day Operational Support</li>
                <li>
                  {" "}
                  L2 Support – Handle Configuration Issues, Troubleshooting,
                  Break/fix support
                </li>{" "}
                <li>
                  L3 Support – Address all Technical Escalations, Customer
                  Coordination to address any complex and critical issues, along
                  with product vendor coordination
                </li>{" "}
                <li>5M-SST Managed Services – Operation Reporting</li>
                <li> Monthly AWS/Azure Infrastructure Consumption</li>
                <li> Monthly Operations Support Service Consumption</li>
                <li>
                  Report Monthly SLA Matrix Report Monthly Backup Exception
                </li>
                <li>
                  Report Monthly Patch Compliance Report Infrastructure Capacity
                  Report
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StandardServices;
