import React from "react";
import ServiceHeader from "../../component/ServiceHeader";
import ContactUs from "../../component/contactus";
import "../../views/PageContent.css";
import Myimage from "../../assets/AI images/Product automation.jpg";

const ProductAutomation = () => {
  return (
    <div>
      {<ServiceHeader title="PRODUCT AUTOMATION" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
        <div className="imgg">
            <img src={Myimage} alt="ProductAutomation" />
          </div>
          <div className="row middle" >
            <h2>Product Automation</h2>
            <p>
              We harness the power of Generative AI to transform product
              automation in the manufacturing industry. Our AI-driven solutions
              streamline production processes, ensure high-quality standards,
              and optimize supply chain operations. Through our innovative AI
              technologies, we help manufacturing companies enhance their
              efficiency, reduce costs, and improve product quality.
            </p>

            <h4>Quality Control</h4>
            <p>
              Utilize advanced image recognition technology to inspect and
              identify defects in products, ensuring high quality. Our AI
              systems can quickly and accurately detect inconsistencies,
              allowing for immediate corrective actions. This results in higher
              product standards, reduced waste, and increased customer
              satisfaction.
            </p>

            <h4>Supply Chain Optimization</h4>
            <p>
              Predict demand and optimize inventory with our sophisticated AI
              algorithms. By analyzing market trends and historical data, our AI
              solutions help reduce waste and improve delivery times. This leads
              to a more efficient supply chain, better resource management, and
              reduced operational costs.
            </p>

            <h4>Robotics in Manufacturing</h4>
            <p>
              Automate repetitive tasks with our state-of-the-art robotics
              solutions, increasing production efficiency and worker safety. Our
              AI-powered robots can perform complex tasks with precision,
              reducing the risk of human error and enhancing overall
              productivity. This automation not only speeds up the manufacturing
              process but also allows workers to focus on more strategic
              activities.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductAutomation;
