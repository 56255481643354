import React from "react";
import ServiceHeader from "../component/ServiceHeader";
import img from "../assets/images/aboutus.png";
import placeholderImg from "../assets/images/aboutus.png"; // Assuming you have a placeholder image
// import "./PageContent.css";
import "./PartnersPage.css";
const partnerData = [
  // {
  //   name: "Five minutes SST",
  //   address:
  //     "No. 18, Manikeswari Road, Kilpauk, Chennai 600010, TamilNadu, India",
  //   phone: "+91 99620 77572",
  //   email: "Syed@FiveMinutesSST.com",
  //   linkedin: "https://www.linkedin.com/in/youknowwhodotin/",
  //   image: require("../assets/images/Five-minutes-SST.jpg"),
  //   role: "Syed Hussain Siraj – CEO, India Operations",

  //   achievements: [
  //     "CEO of Five Minutes (2018 onwards), providing dedicated managed services",
  //     "CEO of Vara United (2016-18), with 3000+ employees providing managed services to a large financial institution in India",
  //     "SVP for 5+ years in Citi Architecture and Technology Engineering group",
  //     "VP for 5+ years in Investment Banking Division in Citigroup in NYC",
  //   ],
  // },
  {
    name: "CalTech IT Solution Private Limited",
    address:
      "Primarc Tower, Suite # 1003, DN-36, Salt Lake City Sec V Kolkata, West Bengal 700091.",
    phone: "+91 9963421374 I +1(609) 759-0116",
    email: "mukesh@sstus.net",
    linkedin: "https://www.linkedin.com/in/mukesh-agarwal-a5aa5021/",
    image: require("../assets/images/caltech_it_solution_logo.jpeg"),
    role: "Mukesh Agarwal",
    achievements: [
      " Director & Co-Founder of CalTech IT Solution Private Limited. Providing dedicated professional & IT services in India & the USA. Overall work experience of around 15 Years. Worked closely into USA IT Staffing & Recruitment Domain.",
      "Worked with Multiple USA-based clients in setting up and managing their operation from India.",
      " Have been part of various Startup Recruitment Operations & Technology Talent Identification.",
    ],
  },
  {
    name: "The DevOps Team",
    address:
      "918, Basementfloor , 1st Main Rd, below Clove Dental, HBR Layout 2nd Block, HRBR Layout, HBR Layout, Bengaluru, Karnataka 560043",
    phone: "+919900053700",
    email: "Support@thedevopsteam.com",
    linkedin: "https://www.linkedin.com/company/thedevopsteam/",
    image: require("../assets/images/thedevopsteam_logo.jpeg"),
    role: "Aslam Latheef Shaikh - CEO",
    achievements: [
      "CEO of The DevOps Team",
      "Over 20 years of experience as an Engineer, Consultant, Architect",
      "Technical expertise in cloud computing, data analytics, and application architecture across industries like banking, pharma, e-commerce, and oil & gas.",
    ],
  },
];

const PartnersPage = () => {
  return (
    <div>
      <ServiceHeader title="Partners" />
      <div className="container">
        {partnerData.map((partner, index) => (
          <div key={index} className="partner-card">
            <div className="partner-image">
              <img src={partner.image} alt={`${partner.name} logo`} />
            </div>
            <div className="partner-details">
              <h3 className="title">{partner.name}</h3>
              <p>{partner.address}</p>
              <p>Phone: {partner.phone}</p>
              <p>
                Email: <a href={`mailto:${partner.email}`}>{partner.email}</a>
              </p>
              <p>
                LinkedIn:{" "}
                <a
                  href={partner.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {partner.linkedin}
                </a>
              </p>
              <p>{partner.role}</p>
              <ul>
                {partner.achievements.map((achievement, idx) => (
                  <li key={idx}>{achievement}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PartnersPage;
