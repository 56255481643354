import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./views/Home";
import ManageCloud from "./views/ManageCloud";
import Migrations from "./views/Migrations";
import SecurityCompliance from "./views/SecurityCompliance";
import Industry from "./views/Industry";
import ProfessionalServices from "./views/ProfessionalServices";
import RPO from "./views/RPO";
import DevelopmentTestingServices from "./views/DevelopmentTestingServices.jsx";
import EnterpriseApplications from "./views/EnterpriseApplications.jsx";
import ContactUs from "./views/ContactUs.jsx";
import SideBar from "./component/SideBar";
import AboutUs from "./views/AboutUs";
import AISectorComponent from "./views/AISectorComponent";
import { ActiveMenuProvider } from "../src/auth/ActiveMenuContext";
import AwsMigrations from "./views/Migrations/AwsMigrations.jsx";
import AzureMigrations from "./views/Migrations/AzureMigration.jsx";
import GcpMigrations from "./views/Migrations/GCPMigrations.jsx";
import HybridCloud from "./views/Migrations/HybridCloud.jsx";
import DisasterRecovery from "./views/Migrations/DisasterRecovery.jsx";
import DatabaseMigrations from "./views/Migrations/DatabaseMigrations.jsx";
import UtilityAndConsumption from "./views/Migrations/UtilityAndComsumption.jsx";
import AwsMangedSer from "./views/ManagedServices/AwsManagedSer.jsx";
import AzureManagedSer from "./views/ManagedServices/AzureManagedSer.jsx";
import GcpMangedSer from "./views/ManagedServices/GcpManagedSer.jsx";
import CloudOptimization from "./views/ManagedServices/CloudOptimization.jsx";
import StandardServices from "./views/ManagedServices/StandardServices.jsx";
import OnDemandServices from "./views/ManagedServices/OnDemandServices.jsx";
import DatabaseAssessment from "./views/SECURITY-ASSESSMENT/Assessments/Database-ass.jsx";
import DataCenterAssessment from "./views/SECURITY-ASSESSMENT/Assessments/DataCenter-ass.jsx";
import HybridcloudAssessment from "./views/SECURITY-ASSESSMENT/Assessments/Hybridcloud-ass.jsx";
import InfrastructureAssessment from "./views/SECURITY-ASSESSMENT/Assessments/Infrastructure-ass.jsx";
import SecurityAssessment from "./views/SECURITY-ASSESSMENT/Assessments/Security-ass.jsx";
import WebApplicationDevelopment from "./views/DevelopmentAndTesting/WebApplicationDevelopment.jsx";
import QualityAssurance from "./views/DevelopmentAndTesting/QualityAssurance.jsx";
import Company from "./views/AboutUs/Company.jsx";
import Careers from "./views/AboutUs/Careers.jsx";
import LeadershipTeams from "./views/AboutUs/LeadershipTeams.jsx";
import Security from "./views/SECURITY-ASSESSMENT/security.jsx";
import Assessment from "./views/SECURITY-ASSESSMENT/assessment.jsx";
import AppAssessment from "./views/SECURITY-ASSESSMENT/Assessments/Application-ass.jsx";
import CloudSecurity from "./views/SECURITY-ASSESSMENT/Security/cloudsecurity.jsx";
import ManagedSecurity from "./views/SECURITY-ASSESSMENT/Security/managedsecurity.jsx";
import AzureSecurity from "./views/SECURITY-ASSESSMENT/Security/azuresecurity.jsx";
import AWSSecurity from "./views/SECURITY-ASSESSMENT/Security/awssecurity.jsx";
import GCPSecurity from "./views/SECURITY-ASSESSMENT/Security/gcpsecurity.jsx";
import Overview from "./views/Industry/overview.jsx";
import FinancialServices from "./views/Industry/financialServices.jsx";
import Healthcare from "./views/Industry/healthcare.jsx";
import Legal from "./views/Industry/legal.jsx";
import LifeSciences from "./views/Industry/lifesciences.jsx";
import MediaEntertainment from "./views/Industry/mediaandentertainment.jsx";
import PublicSector from "./views/Industry/publicsector.jsx";
import Retail from "./views/Industry/retail.jsx";
import SmallMediumBusiness from "./views/Industry/smallandmediumbusiness.jsx";
import PartnersPage from "./views/PartnersPage.jsx";
import HealthcareAI from "./views/GenAI/Healthcare.jsx";
import Autoscribe from "./views/GenAI/Autoscribe.jsx";
import Insurance from "./views/GenAI/Insurance.jsx";
import Utility from "./views/GenAI/Utility.jsx";
import ProductAutomation from "./views/GenAI/ProductAutomation.jsx";
import Financial from "./views/GenAI/Financial.jsx"; 
import Policy from "./views/AboutUs/Policy.jsx";
function App() {
  return (
    <Router>
      <ActiveMenuProvider>
        <SideBar>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/genai" element={<AISectorComponent />}></Route>
            <Route path="/partners" element={<PartnersPage />}></Route>
            {/* Managed Cloud pages */}
            <Route path="/manageCloud" element={<ManageCloud />}></Route>
            <Route path="/awsmanagedser" element={<AwsMangedSer />}></Route>
            <Route
              path="/azuremanagedser"
              element={<AzureManagedSer />}
            ></Route>
            <Route path="/gcpmanagedser" element={<GcpMangedSer />}></Route>
            <Route
              path="/cloudoptimization"
              element={<CloudOptimization />}
            ></Route>
            <Route
              path="/standardedservices"
              element={<StandardServices />}
            ></Route>
            <Route
              path="/ondemandservices"
              element={<OnDemandServices />}
            ></Route>
            {/* Migrations pages */}
            <Route path="/migrations" element={<Migrations />}></Route>
            <Route path="/awsmigrations" element={<AwsMigrations />}></Route>
            <Route
              path="/azuremigrations"
              element={<AzureMigrations />}
            ></Route>
            <Route path="/gcpmigrations" element={<GcpMigrations />}></Route>
            <Route path="/hybridcloud" element={<HybridCloud />}></Route>
            <Route
              path="/disasterrecovery"
              element={<DisasterRecovery />}
            ></Route>
            <Route
              path="/utilityandconsuption"
              element={<UtilityAndConsumption />}
            ></Route>
            <Route
              path="/standardservices"
              element={<StandardServices />}
            ></Route>
            <Route
              path="/ondemandervices"
              element={<OnDemandServices />}
            ></Route>
            <Route
              path="/databasemigrations"
              element={<DatabaseMigrations />}
            ></Route>
            <Route path="/standardservices" element={<StandardServices />} />
            <Route path="/ondemandervices" element={<OnDemandServices />} />
            <Route
              path="/databasemigrations"
              element={<DatabaseMigrations />}
            />
            {/* </Route> */}
            <Route path="/company" element={<Company />}></Route>
            <Route
              path="/leadershipteams"
              element={<LeadershipTeams />}
            ></Route>
            <Route path="/careers" element={<Careers />}></Route>
            <Route
              path="/webapplicationdevelopment"
              element={<WebApplicationDevelopment />}
            ></Route>
            <Route
              path="/qualityassurance"
              element={<QualityAssurance />}
            ></Route>
            <Route
              path="/securityCompliance"
              element={<SecurityCompliance />}
            ></Route>
            <Route path="/industry" element={<Industry />}></Route>
            <Route
              path="/professionalServices"
              element={<ProfessionalServices />}
            ></Route>
            <Route path="/rpo" element={<RPO />}></Route>
            <Route
              path="/developmenttestingservices"
              element={<DevelopmentTestingServices />}
            ></Route>
            <Route
              path="/EnterpriseApplications"
              element={<EnterpriseApplications />}
            ></Route>
            <Route path="/AboutUs" element={<AboutUs />}></Route>
            <Route path="/ContactUs" element={<ContactUs />}></Route>
            <Route path="/security" element={<Security />}></Route>
            <Route path="/assessment" element={<Assessment />}></Route>
            <Route path="/appassessment" element={<AppAssessment />}></Route>
            <Route
              path="/databaseassessment"
              element={<DatabaseAssessment />}
            ></Route>
            <Route
              path="/datacenterassessment"
              element={<DataCenterAssessment />}
            ></Route>
            <Route
              path="/hybridcloudassessment"
              element={<HybridcloudAssessment />}
            ></Route>
            <Route
              path="/infrastructureassessment"
              element={<InfrastructureAssessment />}
            ></Route>
            <Route
              path="/securityassessment"
              element={<SecurityAssessment />}
            ></Route>
            <Route path="/cloudsecurity" element={<CloudSecurity />}></Route>
            <Route
              path="/managedsecurity"
              element={<ManagedSecurity />}
            ></Route>
            <Route path="/azuresecurity" element={<AzureSecurity />}></Route>
            <Route path="/awssecurity" element={<AWSSecurity />}></Route>
            <Route path="/gcpsecurity" element={<GCPSecurity />}></Route>
            <Route path="/overview" element={<Overview />}></Route>
            <Route
              path="/financialServices"
              element={<FinancialServices />}
            ></Route>
            <Route path="/healthcare" element={<Healthcare />}></Route>
            <Route path="/legal" element={<Legal />}></Route>
            <Route path="/lifesciences" element={<LifeSciences />}></Route>
            <Route
              path="/mediaandentertainment"
              element={<MediaEntertainment />}
            ></Route>
            <Route path="/publicsector" element={<PublicSector />}></Route>
            <Route path="/retail" element={<Retail />}></Route>
            <Route
              path="/smallandmediumbusiness"
              element={<SmallMediumBusiness />}
            ></Route>
            <Route path="/healthcareai" element={<HealthcareAI />}></Route>
            <Route path="/autoscribe" element={<Autoscribe />}></Route>
            <Route path="/insurance" element={<Insurance />}></Route>
            <Route path="/utility" element={<Utility />}></Route>
            <Route
              path="/productautomation"
              element={<ProductAutomation />}
            ></Route>
            <Route path="/financial" element={<Financial />}></Route>
            <Route path="/Policy" element={<Policy />}></Route>
          </Routes>
        </SideBar>
      </ActiveMenuProvider>
    </Router>
  );
}

export default App;
