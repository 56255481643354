import React from "react";
import "../PageContent.css";
import img from "../../assets/images/company.jpg";
import ServiceHeader from "../../component/ServiceHeader";
import ContactUs from "../../component/contactus";
const LeadershipTeams = () => {
  return (
    <div className="wrapper">
      <ServiceHeader title="LEADERSHIP TEAMS" />
     
        {/* <div className="row4">h</div> */}

        <div className="row mp">
          <div className="col-sm-3 mpc">
            <ContactUs />
          </div>
          <div className="col-sm-1"></div>
          <div className="col-sm-8">
            <p>
              <br />
              Ganesh Madda – CEO, US Operations
              <ul>
                <li>
                  CEO of Shri Sai Tech LLC (SST), managing enterprise clients
                  for last 10 Years
                </li>
                <li>
                  Managed, Migrated and Helped Enterprise clients to move to
                  Azure, AWS and GCP clouds, saving Millions of dollars
                </li>
                <li>
                  Worked closely with Microsoft sectors like SMC (Small Medium
                  Corporate), Enterprise, Education accounts
                </li>
                <li>
                  Worked as VP for 10 Years in Financial Industry on Wall Street
                </li>
              </ul>
              <p>
                I can be reached at Ganesh@sstus.net or
                Ganesh@FiveMinutesSST.com
                <br />
                LinkedIn:{" "}
                <a href="https://www.linkedin.com/in/ganesh-madda-ceo-sst-866a481/">
                  https://www.linkedin.com/in/ganesh-madda-ceo-sst-866a481/
                </a>
              </p>
              <p>
                Syed Hussain Siraj – CEO, India Operations
                <ul>
                  <li>
                    CEO of Five Minutes (2018 onwards), providing dedicated
                    managed services
                  </li>
                  <li>
                    CEO of Vara United (2016-18), with 3000+ employees providing
                    managed services to a large financial institution in India
                  </li>
                  <li>
                    SVP for 5+ years in Citi Architecture and Technology
                    Engineering group
                  </li>
                  <li>
                    VP for 5+ years in Investment Banking Division in Citigroup
                    in NYC
                  </li>
                </ul>
                I can be reached at Syed@FiveMinutesSST.com
                <br />
                LinkedIn:{" "}
                <a href="https://www.linkedin.com/in/youknowwhodotin/">
                  https://www.linkedin.com/in/youknowwhodotin/
                </a>
              </p>
              <p>
                Mukesh Agarwal
                <ul>
                  <li>
                    Director & Co – Founder of CalTech IT Solution Private
                    Limited. Providing dedicated professional & IT services in
                    India & USA.
                  </li>
                  <li>
                    Overall work experience of around 15 Years. Worked closely
                    into USA IT Staffing & Recruitment Domain.
                  </li>
                  <li>
                    Worked with Multiple USA based clients in setting up and
                    managing their operation from India.
                  </li>
                  <li>
                    Have been part of various Startup Recruitment Operations &
                    Technology Talent Identification.
                  </li>
                </ul>
                I can be reached at mukesh@sstus.net
                <br />
                LinkedIn:
                <a
                  href="https://www.linkedin.com/in/mukesh-agarwal-a5aa5021/
"
                >
                  https://www.linkedin.com/in/mukesh-agarwal-a5aa5021/
                </a>
              </p>
              <p>
                Jayant Aken
                <ul>
                  <li>
                    Chief Operations Officer (COO) SST India Solapur a
                    subsidiary of SST (LLC) USA.
                  </li>
                  <li>
                    Entrepreneur with over 20 years of expertise in several
                    industries.
                  </li>
                  <li>VICE PRESIDENT of Textile Development Foundation.</li>
                  <li>
                    Ex Vice Chairman VIBRANT TERRY TOWELS, an International
                    trade exhibition
                  </li>
                  <li>
                    Worked closely into India IT Staffing & Recruitment Domain.
                  </li>
                  <li>
                    Worked with Multiple Offshore based clients in setting up
                    and managing their operation from India.
                  </li>
                </ul>
                I can be reached at <mailto>jayant@sstus.net</mailto>
                <br />
                LinkedIn:
                <a href="https://www.linkedin.com/in/jayant-aken-83578178/">
                  {" "}
                  https://www.linkedin.com/in/jayant-aken-83578178/
                </a>
              </p>
            </p>
          </div>
        
      </div>
    </div>
  );
};
export default LeadershipTeams;
