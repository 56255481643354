import React from "react";
import img from "../assets/images/professional services.png";
import img1 from "../assets/images/psimg1.png";
import img2 from "../assets/images/psimg2.jpg";
import img3 from "../assets/images/psimg3.jpg";
import img4 from "../assets/images/psimg4.png";
import img5 from "../assets/images/psimg5.png";
import ContactUs from "../component/contactus";
import ServiceHeader from "../component/ServiceHeader";
import "./PageContent.css";

const ProfessionalServices = () => {
  return (
    <div className="wrapper">
      <ServiceHeader title="PROFESSIONAL SERVICES" />

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>

        <div class="col-sm-8">
          <div className="imgg">
            <img align="right" src={img} />
          </div>
          <div className="row middle">
            <h2>Types Of Staffing</h2>
            <p>
              A staffing plan is a well-thought-out road map for ensuring your
              company is fully staffed, with the long-term goal of avoiding
              downtime or loss in production due to retirement or other staff
              turnover. Staffing plans tend to vary from one company to the
              next, depending on industry, size of the organization and
              anticipated growth. There is no one-size-fits-all approach to help
              you effectively staff your business, but with careful planning and
              research, it is possible to have a pipeline of talent ready to
              step in and fill vacancies as they occur.
            </p>
          </div>

          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img1} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="">
                <br />
                <h4>Contract Staffing</h4>
              </a>

              <p>
                Our contract staffing services enable you to fill short-term
                needs for qualified employees. Our clients rely on a contract
                staffing services to support upcoming projects, meet seasonal
                requirements and reduce their workforce during slow periods.
                Hiring individuals on a contract basis allows your company to
                maintain proper staff levels and retain the talent available
                today, while reducing employee-related costs.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img2} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="">
                <br />
                <h4>Direct Hire Staffing</h4>
              </a>

              <p>
                Direct hire is a service Shri Sai Tech helps you recruit and
                hire a full-time employee. With direct hire, we typically manage
                all the hiring details, so you and your core team can stay
                focused on other priorities in the business. In addition, thanks
                to the robust candidate database have in place, our clients can
                gain faster access to higher quality candidates.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img3} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="">
                <br />
                <h4>Temp To Hire Staffing</h4>
              </a>

              <p>
                Temp to hire staffing is a little different from direct hire
                staffing in that an employee works for our clients for a trial
                period of time. At the end of the probationary period, the
                clients then decide whether or not they want to extend a
                full-time offer to the individual. Shri Sai Tech provides pay
                and benefits until the candidate is hired full-time.
                <br />
                <br />
                Why select temp to hire staffing? This arrangement offers our
                clients an opportunity to test out a candidate before hiring
                them on a permanent basis. As a result, they can make a more
                educated hiring decision and reduce the risk of an expensive
                hiring mistake
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img4} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="">
                <br />
                <h4>Long-Term Staffing</h4>
              </a>

              <p>
                Long-term staffing involves taking a proactive approach to our
                clients staffing needs. Most long-term staffing plans cover at
                least one year. When determining such staffing needs, take into
                account past turnover, employees who may be retiring or taking a
                maternity or other leave, expected growth and poorly performing
                staff members. Once they have a clear picture of their expected
                staffing needs, they can develop a plan to help recruit the
                right talent.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img5} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="">
                {" "}
                <br />
                <h4>Strategic Staffing</h4>
              </a>

              <p>
                Strategic staffing involves a combination of short-term,
                long-term and succession planning. This plan takes the company’s
                business plans into account to ensure that goals can be met from
                a talent perspective. Staffing levels are evaluated to determine
                if there is a staffing surplus or deficit. Skills also should be
                assessed to determine if your existing team lacks the ability
                and experience needed to help the company move forward. Often,
                training is included in the strategic staffing plan to rectify
                any gaps in skills.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalServices;
