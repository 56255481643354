import ServiceHeader from "../../component/ServiceHeader";
import "../PageContent.css";
import ContactUs from "../../component/contactus";
import img from "../../assets/images/Google-Cloud-Platform.png";
import img1 from "../../assets/images/GCP1.jpg";
import img2 from "../../assets/images/GCP2.jpg";
import img3 from "../../assets/images/GCP3.jpg";
import img4 from "../../assets/images/GCP4.jpg";
import img5 from "../../assets/images/GCP5.jpg";
import img6 from "../../assets/images/GCP6.jpg";
import React from "react";

const GcpMigrations = () => {
  return (
    <div className="wrapper">
      <ServiceHeader title="GCP MIGRATIONS" />

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div class="imgg">
            <img align="right" src={img} />
          </div>
          <div className="row middle">
            {/* <h4>MIGRATIONS</h4> */}
            <h3>GOOGLE CLOUD PLATFORM MANAGED SERVICES</h3>
            <br />
            <h4>Predictable, Proven Solutions from GCP Experts</h4>
            <p>
              5M-SST is a leader in managing Google Cloud Platform environments
              at any scale.
              <br />
              <br />
              We provide you with a team of certified GCP experts to take
              operational tasks off your plate. As a Google Cloud Platform
              Partner, our services provide leading-edge management and
              optimization practices to ensure security, reduce Google Cloud
              costs, and improve availability.
              <br />
              <br />
              By acting as an extension of your team, whether in a full or
              partial managed services capacity, 5M-SST opens the door to
              innovation by delivering a dependable environment for your most
              critical business functions.
            </p>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img1} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="">
                <br />
                <h4>Always-On Support</h4>
              </a>
              <p>
                Enjoy complete visibility and control when you need it most with
                24/7 support, monitoring, and ticketing.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img2} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="">
                <br />
                <h4>Dedicated Experts</h4>
              </a>
              <p>
                Back your team with 5M-SST Google Cloud certified experts as
                well as a dedicated Client Success Manager.{" "}
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img3} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="">
                <br />
                <h4>Continuous Optimization</h4>
              </a>
              <p>
                Take the complexity out of your cloud environment through
                continuous, expert-driven improvements to your GCP environment.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img4} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="">
                <br />
                <h4>Cloud Cost Optimization</h4>
              </a>
              <p>
                Simplify your billing with predictable, affordable cloud costs
                for your single or multi-cloud environment
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img4} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="">
                <br />
                <h4>Multi-Cloud Masters</h4>
              </a>

              <p>
                Back your multi-cloud environment with proven expertise. Qwinix
                holds partner-status with each of the public clouds (AWS, GCP,
                and Azure).
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img4} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="">
                <br />
                <h4>Security and Compliance</h4>
              </a>
              <p>
                Enjoy peace of mind knowing our team will detect, respond, and
                prevent gaps in cloud security and compliance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GcpMigrations;
