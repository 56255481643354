import React from "react";
import "../PageContent.css";
import img from "../../assets/images/company.jpg";
import ServiceHeader from "../../component/ServiceHeader";
import ContactUs from "../../component/contactus";

const Company = () => {
  return (
    <div className="wrapper"> 
      <ServiceHeader title="COMPANY" />
      
        <div className="row mp">
          <div className="col-sm-3 mpc">
            <ContactUs />
          </div>
          <div className="col-sm-1"></div>
          <div className="col-sm-8">
            <div className="imgg">
              <img
                className="company-img"
                src={img}
                alt="Company"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
            </div>
            <div className="row middle">
              <p>
                SST was conceptualized by bringing two existing entities
                together in order to assist enterprises on their cloud journey.
              </p>
              <p>
                Enterprise clients are excited to know more about the latest
                cloud offerings but are in different phases of this journey.
                Some are still contemplating while a few have hybrid models and
                a few have already taken the leap to move into cloud 100%.
              </p>
              <p>
                No matter which part of the journey you are in, SST aims to hand
                hold you in this journey.
              </p>
              <p>
                Has always focused on providing managed services and support
                with five minute response times.
              </p>
              <p>
                Shri Sai Technology (SST) has always been taking care of client
                needs and providing end to end solutions to the clients.
              </p>
              <p>
                These two entities coming together to provide world class
                managed services to enterprise clients is the uniqueness of the
                SST organization.
              </p>
            </div>
          </div>
        </div>
      </div>
    
  );
};

export default Company;
