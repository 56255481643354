import React from "react";
import ServiceHeader from "../../component/ServiceHeader";
import ContactUs from "../../component/contactus";
import "../../views/PageContent.css";
import Myimage from "../../assets/AI images/Utility.jpg";

const Utility = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="UTILITY" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
        <div className="imgg">
            <img src={Myimage} alt="utility" />
          </div>
          <div className=" row middle">
            <h2>Utility</h2>
            <p>
              We are revolutionizing the utility industry by integrating
              cutting-edge Generative AI technology. Our advanced solutions
              enhance operational efficiency, improve reliability, and provide
              actionable insights, helping utility providers meet the demands of
              modern energy management. Through innovative AI-driven systems, we
              empower utility companies to optimize their operations, reduce
              costs, and deliver superior services to their customers.
            </p>

            <h4>Smart Grid Management</h4>
            <p>
              Optimize energy distribution and load balancing with our AI-driven
              smart grid management solutions. By continuously analyzing data
              from various sources, our AI systems enhance the efficiency and
              reliability of energy grids. This ensures a stable energy supply,
              reduces transmission losses, and minimizes the risk of outages,
              delivering a more resilient energy infrastructure.
            </p>

            <h4>Predictive Maintenance</h4>
            <p>
              Reduce downtime and maintenance costs with our predictive
              maintenance capabilities. Utilizing advanced algorithms, our AI
              identifies potential equipment failures before they occur,
              allowing for proactive maintenance. This early detection of issues
              helps in scheduling timely repairs, extending the lifespan of
              equipment, and preventing costly unplanned outages.
            </p>

            <h4>Energy Consumption Analytics</h4>
            <p>
              Monitor and analyze consumer energy usage patterns with our
              comprehensive energy consumption analytics. Our AI systems provide
              detailed insights and recommendations for saving energy, helping
              consumers and businesses alike to optimize their energy
              consumption. This not only leads to cost savings but also promotes
              sustainable energy practices by encouraging more efficient use of
              resources.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Utility;
