import ServiceHeader from "../../component/ServiceHeader";
import "../PageContent.css";
import ContactUs from "../../component/contactus";
import img from "../../assets/images/aws-migration-steps (1).png";

const AwsMigrations = () => {
  return (
    <div className="wrapper">
      <ServiceHeader title="AWS MIGRATIONS" />

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div className="imgg">
            <img align="right" src={img} />
          </div>
          <div className="row middle">
            <h2>AWS MIGRATIONS</h2>
            <p>
              <b> AWS Database Migration Service</b>
              <p>
                Migrate your databases to AWS with minimal downtime More than
                350,000 databases migrated using AWS Database Migration Service
              </p>

              <p>
                AWS Database Migration Service helps you migrate databases to
                AWS quickly and securely. The source database remains fully
                operational during the migration, minimizing downtime to
                applications that rely on the database. The AWS Database
                Migration Service can migrate your data to and from the most
                widely used commercial and open-source databases.
              </p>

              <p>
                AWS Database Migration Service supports homogeneous migrations
                such as Oracle to Oracle, as well as heterogeneous migrations
                between different database platforms, such as Oracle or
                Microsoft SQL Server to Amazon Aurora. With AWS Database
                Migration Service, you can continuously replicate your data with
                high availability and consolidate databases into a
                petabyte-scale data warehouse by streaming data to Amazon
                Redshift and Amazon S3.
              </p>

              <p>
                When migrating databases to Amazon Aurora, Amazon Redshift,
                Amazon DynamoDB, or Amazon DocumentDB (with MongoDB
                compatibility) you can use DMS free for six months.
              </p>
              <h3>Benefits</h3>
              <p>
                <b>Simple to use</b>

                <p>
                  AWS Database Migration Service is simple to use. There is no
                  need to install any drivers or applications, and it does not
                  require changes to the source database in most cases. You can
                  begin a database migration with just a few clicks in the AWS
                  Management Console. Once the migration has started, DMS
                  manages all the complexities of the migration process
                  including automatically replicating data changes that occur in
                  the source database during the migration process. You can also
                  use this service for continuous data replication with the same
                  simplicity.
                </p>
                <b>Minimal downtime</b>
                <p>
                  AWS Database Migration Service helps you migrate your
                  databases to AWS with virtually no downtime. All data changes
                  to the source database that occur during the migration are
                  continuously replicated to the target, allowing the source
                  database to be fully operational during the migration process.
                  After the database migration is complete, the target database
                  will remain synchronized with the source for as long as you
                  choose, allowing you to switchover the database at a
                  convenient time.
                </p>
                <b>Supports widely used databases</b>
                <p>
                  AWS Database Migration Service can migrate your data to and
                  from most of the widely used commercial and open-source
                  databases. It supports homogeneous migrations such as Oracle
                  to Oracle, as well as heterogeneous migrations between
                  different database platforms, such as Oracle to Amazon Aurora.
                  Migrations can be from on-premises databases to Amazon RDS or
                  Amazon EC2, databases running on EC2 to RDS, or vice versa, as
                  well as from one RDS database to another RDS database. It can
                  also move data between SQL, NoSQL, and text-based targets.
                </p>
                <b>Low cost</b>
                <p>
                  AWS Database Migration Service is a low-cost service. You only
                  pay for the compute resources used during the migration
                  process and any additional log storage. Migrating a
                  terabyte-sized database can be done for as little as $3. This
                  applies to both homogeneous and heterogeneous migrations of
                  any supported databases. This is in stark contrast to
                  conventional database migration methods which can be very
                  expensive.
                </p>
                <b>Fast and easy to set-up</b>
                <p>
                  You can set up a migration task within minutes in the AWS
                  Management Console. A migration task is where you define the
                  parameters the AWS Database Migration Service uses to execute
                  the migration. This includes setting up connections to the
                  source and target databases, as well as choosing the
                  replication instance used to run the migration process. Once
                  set up, the same task can be used for test runs before
                  performing the actual migration.
                </p>
                <b>Reliable</b>
                <p>
                  The AWS Database Migration Service is highly resilient and
                  self–healing. It continually monitors source and target
                  databases, network connectivity, and the replication instance.
                  In case of interruption, it automatically restarts the process
                  and continues the migration from where it was halted. A
                  multi-AZ option allows you to have high-availability for
                  database migration and continuous data replication by enabling
                  redundant replication instances.
                </p>
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwsMigrations;
