import React from "react";
import SSTLogo from "../assets/images/sstlogo.png";
export default function Header() {
  return (
    <div>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light"
        style={{ backgroundColor: "#f5fbfe" }}
      >
        <a className="navbar-brand" href="/">
          <img
            src={SSTLogo}
            alt="SST Logo"
            style={{ width: "100px", height: "auto", marginLeft: "50%" }}
          />
        </a>
      </nav>
    </div>
  );
}
