import React from "react";
import Myimage from "../../../assets/images/cloud-security-1.jpg";

import "../../../views/PageContent.css";
import ServiceHeader from "../../../component/ServiceHeader";
import ContactUs from "../../../component/contactus";

const cloudsecurity = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="CLOUD SECURITY" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>

        <div className="col-sm-8">
          <div className="imgg">
            <img src={Myimage} alt="security" />
          </div>

          <div className="middle">
            <h2>CLOUD SECURITY</h2>
            <p>
              Cloud security, also known as cloud computing security, consists
              of a set of policies, controls, procedures, and technologies that
              work together to protect cloud-based systems, data, and
              infrastructure. These security measures are configured to protect
              cloud data, support regulatory compliance, and protect customers’
              privacy as well as setting authentication rules for individual
              users and devices. From authenticating access to filtering
              traffic, cloud security can be configured to the exact needs of
              the business. And because these rules can be configured and
              managed in one place, administration overheads are reduced and IT
              teams empowered to focus on other areas of the business.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default cloudsecurity;
