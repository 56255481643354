// Footer.js
import React from 'react';
import './Footer.css'; // Make sure to import the CSS file
import ContactForm from './ContactForm';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();
    const navigateToContact = () => {
        navigate('/ContactUs');
      };
  return (
    <>
    <footer className="footer1">
      <div className="footer-content">
        <h3>Ready for Growth?</h3>
        <p>Enquire about your requirements, our digital expertise helps you to achieve top results in any field.</p>
        <button className="footer-btn" onClick={navigateToContact}>Contact Us</button>
      </div>
      </footer>
      <footer className="footer2">
      <div className="footer-bottom">
        <p>Copyright © 2016 SST Managed Services demo. All Rights Reserved. Designed By DesignArc</p>        
      </div>
    </footer>
    </>
  );
};

export default Footer;
