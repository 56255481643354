import React from "react";
import img from "../assets/images/dts.jpg";
import img1 from "../assets/images/dts1.jpg";
import img2 from "../assets/images/dts2.jpg";
import img3 from "../assets/images/dts3.png";
import img4 from "../assets/images/dts5.png";
import img5 from "../assets/images/rpo5.jpg";
import ContactUs from "../component/contactus";
import ServiceHeader from "../component/ServiceHeader";
import "./PageContent.css";

const DevelopmentTestingServices = () => {
  return (
    <div className="wrapper">
      <ServiceHeader title="MOBILE APPLICATION DEVELOPMENT" />
      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>

        <div className="col-sm-8">
          <div className="imgg">
            <img align="right" src={img} />
          </div>
          <div className="row middle">
            <h2>Crating Apps Since 2018</h2>
            <p>
              Since 2018, Our award-winning team has built an unparalleled suit
              of applications for our enterprise clients to fully automate their
              workflow management and other internal processes. We have a great
              team of experienced consultants and Software Engineers who can
              take your idea and bring it to realization. Be it native or hybrid
              apps, we optimize the code and design of your mobile apps
              compliant with the latest technological standards to ensure the
              best performance.
            </p>
            <br />
          </div>
          <h4>Our Services</h4>

          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img1} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a href="" className="fontcolor">
                <br />
                <h4>Strategy</h4>
              </a>

              <p>
                Partnering with you on transformation & growth opportunities to
                achieve your goals faster.
                <ol>
                  <li>Go-to-market strategy</li>
                  <li>Business model strategy</li>
                  <li>Sales, marketing & partnership strategy</li>
                  <li>Fundraising strategy</li>
                  <li>Hiring strategy</li>
                </ol>
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img2} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a href="" className="fontcolor">
                <br />
                <h4>Design</h4>
              </a>
              <p>
                Listening to your users to uncover opportunity and turn your
                target market into customers and customers into fans
                <ol>
                  <li>Lean Design / Design Sprints</li>
                  <li>Competitive Analysis</li>
                  <li>User Story Collection</li>
                  <li>User Persona Creation</li>
                  <li>Rapid Prototyping</li>
                  <li>User Experience (UX) Design</li>
                  <li>User Interface (UI) Design</li>
                  <li>Qualitative & Quantitative User Testing</li>
                </ol>
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img3} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a href="" className="fontcolor">
                <br />
                <h4>Design And Development</h4>
              </a>
              <p>
                <b>Mobile Apps</b>
                <br />
                We have perfected the art of iOS and Android applications. Our
                highly accomplished and professionally trained iOS, Android
                developers, and designers make sure we deliver crisp, top-notch
                mobile applications. We understand the importance of graphic
                design on mobile applications so we make sure every app we work
                on is visually stunning, easy to use and can be utilized by a
                broad range of audiences.
                <br />
                <b>Tablet Apps</b>
                <br />
                We have rich experience in developing and designing high
                performance, scalable, and secure apps that are compatible with
                larger screens and higher resolutions on iPads and Android
                tablets.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img4} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a href="" className="fontcolor">
                <br />
                <h4>Marketing</h4>
              </a>

              <p>
                No product launch plan is complete without the proper marketing.
                We’ll help you find the right marketing partner to get
                discovered and retain users.
                <ol>
                  <li>App Store Optimization</li>
                  <li>Preview & Explainer Videos</li>
                  <li>Homepage & Email Templates</li>
                  <li>PR</li>
                  <li>Paid User Acquisition/Media Planning & Buying</li>
                  <li>Engagement Marketing</li>
                </ol>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevelopmentTestingServices;
