import React from "react";
import "./PageContent.css";
import img from "../assets/images/aboutus.png";
import ServiceHeader from "../component/ServiceHeader";
import trikaLogo from "../assets/images/trika_technologies_logo.jpeg";
import conceptvinesLogo from "../assets/images/conceptvines-logo.jpg";
import elemrexLogo from "../assets/images/elemrexlogo.png";

const AboutUs = () => {
  return (
    <div>
      <ServiceHeader title="ABOUT US" />
      <div className="container">
        <div className="row mp customRow">
          {/* <div className="col-sm-1"></div> */}
          <div className="col-sm-3 abt1 customImage">
            <img align="right" src={img} alt="img-sst" />
          </div>
          <div className="col-sm-8">
            <p>
              <br />
              Shri Sai Tech understands how crucial it is to find the right
              employee with the right skill sets who can contribute to the
              success of your business. Whether you are a small business or a
              large corporate with your own HR department, finding the right
              people is never easy. This is where we can help.
              <br />
              <br />
              We have a workforce that is spread all over USA with just one
              mission to fulfil – finding the right people to meet your specific
              needs. You may need people with a certain level of experience in a
              particular domain, or in a specific location. No matter what you
              require, our competent staffing services division will find people
              who will meet the unique needs of your company.
              <br />
              <br />
            </p>
          </div>
        </div>
        <div className="row mp">
          {/* <div className="col-sm-1"></div> */}
          <div className="customTitleContainer">
            <h3>See Our Clients</h3>
            <div className="client-list">
              <div className="client-item">
                <img src={trikaLogo} alt="Trika Logo" className="client-logo" />
                <p className="cTitle">
                  Trika - Innovative solutions for modern businesses.
                </p>
              </div>
              <div className="client-item">
                <img
                  src={conceptvinesLogo}
                  alt="Conceptvines Logo"
                  className="client-logo"
                />
                <p className="cTitle">
                  Conceptvines - Transforming concepts into reality.
                </p>
              </div>
              <div className="client-item">
                <img
                  src={elemrexLogo}
                  alt="Elemrex Logo"
                  className="client-logo"
                />
                <p className="cTitle">
                  Elemrex - Pioneering excellence in various industries.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="col-sm-1"></div> */}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default AboutUs;
