import ServiceHeader from "../../component/ServiceHeader";
import "../PageContent.css";
import ContactUs from "../../component/contactus";
import img from "../../assets/images/standartservices.png";
const OnDemandServices = () => {
  return (
    <div className="wrapper">
      <ServiceHeader title="ONDEMAND SERVICES" />

      {/* <div className="row4">h</div> */}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div className="imgg">
            <img align="right" src={img} />
          </div>
          <div className="row middle">
            <h2>ONDEMAND SERVICES</h2>
            <p>
              <br />
              <br />
              <b>On-Demand Managed Services:</b>
              <ul>
                <li>Managed Database</li>
                <li>Periodic Health Check & Corrective Actions</li>
                <li>Preventive Maintenance</li>
                <li>Performance Tuning</li>
                <li>Environment Management (Dev/Test/Staging/Production)</li>
                <li>Deep Security</li>
                <li>Threat Analytics</li>
                <li>Web Application Firewall</li>
                <li>Intrusion Management</li>
                <li>Network Security Management</li>
                <li>Managed Network</li>
                <li>Network Security Groups</li>
                <li>Azure/AWS VPN/ Firewalls/3rd Part Appliances</li>
                <li>Express Route, Vnet & Subnet</li>
                <li>Managed Operations</li>
                <li>Technical Support (L1/L2/L3)</li>
                <li>Vendor Support Coordination</li>
                <li>Process Automation & Dev/</li>
                <li>Disaster Recovery</li>
                <li>ITIL Service Management Practices</li>
                <li>Ad-hoc Resource</li>
                <li>Network Engineer</li>
                <li>Database Engineer</li>
                <li>Cloud Architect</li>
                <li>O365, Security, etc.</li>
              </ul>
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OnDemandServices;
