import React from "react";
import Myimage from "../../../assets/images/awssecurity.png";

import "../../../views/PageContent.css";
import ServiceHeader from "../../../component/ServiceHeader";
import ContactUs from "../../../component/contactus";

const awssecurity = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="AWS SECURITY" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>

        <div className="col-sm-8">
          <div className="imgg">
            <img src={Myimage} className="Myimg" alt="security" />
          </div>

          <div className="middle">
            <h2>AWS SECURITY</h2>
            <p>
              AWS provides services that help you protect your data, accounts,
              and workloads from unauthorized access. AWS data protection
              services provide encryption and key management and threat
              detection that continuously monitors and protects your accounts
              and workloads.
              <br></br>
              As an AWS customer, you will benefit from a data center and
              network architecture built to meet the requirements of the most
              security-sensitive organizations. Security in the cloud is much
              like security in your on-premises data centers—only without the
              costs of maintaining facilities and hardware.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default awssecurity;
