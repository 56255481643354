import React from "react";
import Myimage from "../../assets/images/abc.png";
import Myimage1 from "../../assets/images/FinTech_Compliance_Cloud_Computing_Thumbnail.jpg";
import Myimage2 from "../../assets/images/healthcare-mobile-apps-development.png";
import Myimage3 from "../../assets/images/Legal-Justice.jpg";
import Myimage4 from "../../assets/images/Life-Sciences.jpg";
import Myimage5 from "../../assets/images/Media-and-Entertainment.jpg";

import Myimage6 from "../../assets/images/PUBLICSECTOR1.jpg";
import Myimage7 from "../../assets/images/On-Demand-Services.jpg";
import Myimage8 from "../../assets/images/Small-Medium.jpg";

import "../../views/PageContent.css";
import ContactUs from "../../component/contactus";

import ServiceHeader from "../../component/ServiceHeader";
const Overview = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="OVERVIEW" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div className="imgg">
            <img src={Myimage} className="Myimg" alt="industry" />
          </div>

          <div className="row middle">
            <br />
            <h2>Overview</h2>
            <p>
              By delivering this computing, storage, and applications as a
              service, not a product, the cloud offers both a cost and business
              advantage.
            </p>
          </div>

          <div class="IMG">
            <div class=" col-sm-3">
              <img src={Myimage1} alt="industry2" className="Myimg" />
            </div>
            <div class=" col-sm-9 rightcont">
              <a href="/financialservices" className="fontcolor">
                <br />
                <h3>Financial Services</h3>
              </a>
              <p>
                Entering the cloud is the first step on this journey towards
                digital transformation. It enables banks to focus time
                previously spent running and maintaining costly IT
                infrastructure, on activities of greater importance to their
                customers.
              </p>
            </div>
          </div>
          <div class="IMG">
            <div class=" col-sm-3">
              <img src={Myimage2} alt="industry2" className="Myimg" />
            </div>
            <div class=" col-sm-9 rightcont">
              <a href="/healthcare" className="fontcolor">
                {" "}
                <br /> <h3>Healthcare</h3>
              </a>
              <p>
                In today’s world compliance is a basic requirement when working
                with health systems. Check that box faster with 5Minute-SST.
              </p>
            </div>
          </div>
          <div class="IMG">
            <div class=" col-sm-3">
              <img src={Myimage3} alt="industry2" className="Myimg" />
            </div>
            <div class=" col-sm-9 rightcont ">
              <a href="/legal" className="fontcolor">
                <br />
                <h3>Legal</h3>
              </a>
              <p>
                That’s a projection we’ve made in a Legal Business Report,
                titled The Next Five Years: Future of Legal Technology. The
                cloud is one of three key legal trends in the report that we
                forecast will have a considerable influence on the business of
                law in the near term.
              </p>
            </div>
          </div>
          <div class="IMG">
            <div class=" col-sm-3">
              <img src={Myimage4} alt="industry2" className="Myimg" />
            </div>
            <div class=" col-sm-9 rightcont">
              <a href="/lifesciences" className="fontcolor">
                <br />
                <h3>Life Sciences</h3>{" "}
              </a>
              <p>
                Most life sciences companies now face increasing consumer,
                portfolio, regulatory, and operating challenges on a daily basis
                as they carry on their search for innovative health solutions.
              </p>
            </div>
          </div>
          <div class="IMG">
            <div class=" col-sm-3">
              <img src={Myimage5} alt="industry2" className="Myimg" />
            </div>
            <div class=" col-sm-9 rightcont ">
              <a href="/mediaandentertainment" className="fontcolor">
                <br />
                <h3>Media and Entertainment</h3>
              </a>
              <p>
                Today’s consumers of media and entertainment content expect
                flexibility and choices. In addition, consumer demand is
                unpredictable, with spikes and surges in viewership that have to
                be dealt with in real-time
              </p>
            </div>
          </div>
          <div class="IMG">
            <div class=" col-sm-3">
              <img src={Myimage6} alt="industry2" className="Myimg" />
            </div>
            <div class=" col-sm-9 rightcont ">
              <a href="/publicsector" className="fontcolor">
                <br />
                <h3>Public Sector</h3>
              </a>
              <p>
                Cloud adoption has been on rising for decades and has now
                reached a level that we don’t see any ‘non-cloud IT environment
                around.
              </p>
            </div>
          </div>
          <div class="IMG">
            <div class=" col-sm-3">
              <img src={Myimage7} alt="industry2" className="Myimg" />
            </div>
            <div class=" col-sm-9 rightcont">
              <a href="/retail" className="fontcolor">
                <br />
                <h3>Retail</h3>
              </a>
              <p>
                It is common knowledge that the retail industry across the globe
                is under severe pressure to keep their businesses running. Of
                all retailers, it has been store operators who have largely
                suffered due to the growth of online shopping as well as
                discount stores to an extent.
              </p>
            </div>
          </div>
          <div class="IMG">
            <div class=" col-sm-3">
              <img src={Myimage8} alt="industry2" className="Myimg" />
            </div>
            <div class=" col-sm-9 rightcont">
              <a href="/smallandmediumbusiness" className="fontcolor">
                <br />
                <h3>Small & Medium Business</h3>
              </a>
              <p>
                The past five years have seen a gradual shift of mid-sized firms
                moving their IT to the cloud. Their reasons vary from a desire
                to modernize like their large firm counterparts to the need for
                certain cloud-only solutions, or the pursuit of the deep
                security afforded by public cloud providers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
