import React from "react";
import img from "../assets/images/Migrations.jpg";
import img1 from "../assets/images/aws-migration-steps (1).png";
import img2 from "../assets/images/img2.jpg";
import img3 from "../assets/images/GCP-Mi-new.jpg";
import img4 from "../assets/images/Hybrid-Cloud-Header-Image.png";
import img5 from "../assets/images/database_migration_shutterstock_hanss.jpg";
import img6 from "../assets/images/2018-11-14-5-Ways-Cloud-Based-Solutions-Improve-Disaster-Recovery-for-Banks-and-Credit-Unions-Header-Blog-Image.png";
import img7 from "../assets/images/UC.jpg";
import ContactUs from "../component/contactus";
import ServiceHeader from "../component/ServiceHeader";
import "./PageContent.css";
const Migrations = () => {
  return (
    <div className="wrapper">
      <ServiceHeader title="MIGRATIONS" />

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1 mpc"></div>

        <div className="col-sm-8">
          <div className="imgg">
            <img align="right" src={img} alt="sst-migration" />
          </div>
          <div className="row middle">
            <h2>Migrations</h2>
            <p>
              Cloud <i>migration</i> is the process of moving data, applications
              or other business elements to a cloud computing environment.
            </p>
          </div>

          <div className="IMG">
            <div className="col-sm-3">
              <img
                align="center"
                src={img1}
                alt="sst-awsmigrations"
                class="Myimg"
              />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="/awsmigrations">
                {" "}
                <br />
                <h4>AWS Database Migration Service</h4>
              </a>

              <p>
                Migrate your databases to AWS with minimal downtime More than
                350,000 databases migrated using AWS Database Migration Service
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img
                align="center"
                src={img2}
                alt="sst-azuremigrations"
                class="Myimg"
              />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="/azuremigrations">
                <br />
                <h4>Azure migration</h4>
              </a>

              <p>
                5 Minute-SST has helped 400+ customers accelerate migration,
                avoid common pitfalls, and meet security and compliance
                requirements.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img3} class="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="/gcpmigrations">
                <br />
                <h4>GCP migration</h4>
              </a>

              <p>
                We provide you with a team of certified GCP experts to take
                operational tasks off your plate. As a Google Cloud Platform
                Partner, our services provide leading-edge management and
                optimization practices to ensure security, reduce Google Cloud
                costs, and improve availability.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img4} class="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="/hybridcloud">
                <br />
                <h4>Hybrid Cloud Migration</h4>
              </a>

              <p>
                Your mission-critical applications need to be secure and
                available regardless of your cloud platform choice. 5Minute-SST
                has decades of experience helping customers develop tailored
                solutions to meet the challenges of today with a design for
                future success.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img5} class="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="/disasterrecovery">
                <br />
                <h4>Disaster Recovery (DR)</h4>
              </a>

              <p>
                Making the decision to move to cloud-based disaster recovery
                seems like a no-brainer, however, companies must take a step
                back and consider what makes up an effective DRaaS solution.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img
                align="center"
                src={img6}
                alt="sst-databasemigrations"
                class="Myimg"
              />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="/databasemigrations">
                <br />
                <h4>Database Migration</h4>
              </a>

              <p>
                Migrating your on-premises database to AWS or Azure can reduce
                the burden of database management, significantly cut licensing
                costs, and enable you to add storage capacity easily on demand.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img7} class="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a className="fontcolor" href="/awsmigrations">
                <br />
                <h4>Utilization and Consumption</h4>
              </a>

              <p>
                With the use of the cloud over time, the IT environment of the
                cloud can be dragged away from its initial state of being
                optimized, resulting in a degraded security mechanism and
                undocumented and undesirable changes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Migrations;
