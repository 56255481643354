import React from "react";
import Myimage from "../../assets/images/FinTech_Compliance_Cloud_Computing_Thumbnail.jpg";
import "../../views/PageContent.css";
import ContactUs from "../../component/contactus";
import ServiceHeader from "../../component/ServiceHeader";

const FinancialServices = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="FINANCIAL SERVICES" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8 ">
          <div className="imgg">
            <img src={Myimage} alt="finser" />
          </div>

          <div className="middle">
            <p>
              {" "}
              <h2>Financial Services</h2>
              By delivering this computing, storage, and applications as a
              service, not a product, the cloud offers both a cost and business
              advantage.
            </p>

            <p>
              In recent years, there has been a significant shift in the
              relationship between banks and the public cloud. While financial
              institutions were initially reluctant to embrace the technology,
              they are now amongst the most likely to do so. According the
              Culture of Innovation Index, recently published by ACI Worldwide
              and Ovum, 92 percent of corporate banks are either already making
              significant use of the cloud, or planning to make further
              investments in 2019/20.
            </p>
            <p>
              But what has prompted this shift? While there has been some debate
              about the role of regulatory attitudes, I believe it mainly comes
              down to the fact that banks now realise that the cloud is a key
              enabler towards the digital transformation of their business which
              allows them focus on activity which enhances their customer
              experience and compete with new entrants. Coupled with the
              understanding that it is secure and that cloud providers have more
              spending power to maintain their clouds, banks are fast realising
              that the cloud is a clear requirement to leveraging the digital
              technology required for their future success.
            </p>
            <p>
              Improved customer experience through faster solution deployment As
              mobile commerce has become more prevalent in the past five to ten
              years, consumers and merchants increasingly expect a better and
              importantly real-time customer experience. At the same time,
              neo-banks and fintech’s (most of which are cloud native and focus
              heavily on customer innovation) have burst onto the scene, adding
              further pressure on incumbents to up their game when it comes to
              delivering added value to their customers. In this environment,
              making small incremental gains, through for example introducing
              e-statements or self-service portals, no longer cuts it. Instead,
              banks have to transform their legacy IT and technology systems to
              remain relevant.
            </p>
            <p>
              Entering the cloud is the first step on this journey towards
              digital transformation. It enables banks to focus time previously
              spent running and maintaining costly IT infrastructure, on
              activities of greater importance to their customers. Additionally,
              it allows them to partner with fintech’s more easily.
            </p>
            <p>
              If fintech’s want to connect to banks that are not in the cloud,
              both parties have to go through a lengthy and complex process, not
              just once but multiple times if they are partnering with more than
              one player. Whereas once in the cloud, banks can offer Application
              Programme Interfaces (APIs) that are much easier for fintech’s to
              connect to. This is important because the banks and fintech’s that
              will thrive are those who partner together and capitalise on each
              other’s strengths to deliver a best in className customer
              experience and strengthen their collective offerings.
            </p>
            <br></br>
            <p>
              <b>Cost optimisation</b>
              <br></br>
              Historically, many banks ran their own data centres. But as this
              is extremely resource and cost- intensive, and customers expect
              the infrastructure to simply work, banks have had to rethink their
              approach. Imagine a scenario where a UK-based bank is looking to
              establish a presence on another continent. In the past, the bank
              may have had to purchase additional data centres to ensure
              resilience and meet peak demand. However, this meant that for most
              of the year, the business was spending hundreds of millions of
              pounds to run and maintain data centres that are only 10 percent
              full. This is an example of banks working in a capital expense
              (CAPEX) model, a highly inefficient and cost intensive as your
              infrastructure needs to meet peak demand, even if your volumes are
              lower for the rest of the year. Adopting the cloud enables banks
              to move to an operating expense (OPEX) model, where they can enjoy
              elastic scalability and only pay for what they need. This gives
              them the agility to scale their data storage up or down depending
              on the needs of the business. It also enables financial
              institutions to focus resources on what really matters: driving
              innovation and delivering an excellent customer experience,
              through quicker product iteration.
              <br></br>
              As the cloud negates the need for banks to buy data centres (and
              carry out the domestic compliance requirements that go along with
              this) it gives them the ability to expand into international
              markets more easily.
              <br></br>
              One of the main reasons banks were initially hesitant to adopt the
              cloud is because they were afraid that moving their sensitive data
              off-premise and into the cloud would leave them vulnerable to
              security breaches. However, they are increasingly coming around to
              the fact that the big cloud providers are spending more on
              security than they ever could on their own.
              <br></br>
              Adopting the cloud enables banks to run a more cost-effective
              operating model, while providing them with the agility to adapt to
              the ever-changing financial environment and enter new markets.
              Ultimately, this means they can focus their time and effort on
              delivering the customer experience required to remain relevant in
              an increasingly competitive environment.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialServices;
