import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import healthcare from "../assets/AI images/healthcare_sector.png";
import autoscribe from "../assets/AI images/autoscribe.png";
import insurance from "../assets/AI images/insurance_sector.png";
import utility from "../assets/AI images/utility_sector.png";
import product from "../assets/AI images/production_sector.png";
import financial from "../assets/AI images/financial_sector.png";
import image from "../assets/images/Frame 3.jpg";
import { Col, Row } from "react-bootstrap";
import ServiceHeader from "../component/ServiceHeader";
import { Link } from "react-router-dom";
import "./AISectorComponent.css";
const data = [
  {
    title: "Healthcare",
    text: (
      <>
        <p className="p-aicontent">
          <strong>Patient-Physician Autoscribe:</strong> Automatically
          transcribes consultations and patient encounters, ensuring accurate
          medical records and allowing physicians to focus on patient care. AI
          systems streamline the prescription process, reducing errors and
          interactions by automatically generating medication orders based on
          patient history and current diagnosis.
        </p>
        <p className="p-aicontent">
          <strong>MRI & CT Scan Analysis:</strong> Uses advanced image
          processing to assist in diagnosing and planning treatment by analyzing
          medical imaging more accurately and rapidly than traditional methods.
        </p>

        <p className="p-aicontent">
          <strong>Appointment Booking:</strong> AI-powered scheduling systems
          improve patient experience by offering convenient appointment booking
          options, reminders, and rescheduling assistance.
        </p>
      </>
    ),
    imageUrl: healthcare,
    link: "/healthcareai",
  },
  {
    title: "Autoscribe",
    text: (
      <>
        <p className="p-aicontent">
          <strong>Speech Recognition:</strong> Converts speech into text in
          real-time, aiding in documentation and compliance.
        </p>
        <p className="p-aicontent">
          <strong>Natural Language Processing (NLP):</strong> Improves accuracy
          over time by learning from corrections and adapting to specific
          terminologies.
        </p>
        <p className="p-aicontent">
          <strong>Integration with EHRs:</strong> Automatically inputs patient
          information into Electronic Health Records (EHRs), reducing manual
          errors and saving time.
        </p>
      </>
    ),
    imageUrl: autoscribe,
    link: "/autoscribe",
  },
  {
    title: "Insurance",
    text: (
      <>
        <p className="p-aicontent">
          <strong>Claims Processing:</strong> Automates the claims process, from
          initiation to review, reducing processing time and fraud.
        </p>
        <p className="p-aicontent">
          <strong>Personalized Policies:</strong> Uses data analytics to offer
          customized insurance policies based on individual risk profiles.
        </p>
        <p className="p-aicontent">
          <strong>Customer Service Bots:</strong> Provides 24/7 customer
          support, handling inquiries and guiding users through the insurance
          process.
        </p>
      </>
    ),
    imageUrl: insurance,
    link: "/insurance",
  },
  {
    title: "Utility",
    text: (
      <>
        <p className="p-aicontent">
          <strong>Smart Grid Management:</strong> AI optimizes energy
          distribution and load balancing, improving efficiency and reliability.
        </p>
        <p className="p-aicontent">
          <strong>Predictive Maintenance:</strong> Identifies potential
          equipment failures before they occur, reducing downtime and
          maintenance costs.
        </p>
        <p className="p-aicontent">
          <strong>Energy Consumption Analytics:</strong> Monitors and analyzes
          consumer energy usage patterns to provide insights and recommendations
          for saving energy.
        </p>
      </>
    ),
    imageUrl: utility,
    link: "/utility",
  },
  {
    title: "Product Automation",
    text: (
      <>
        <p className="p-aicontent">
          <strong>Quality Control:</strong> Uses image recognition to inspect
          and identify defects in products, ensuring high quality.
        </p>
        <p className="p-aicontent">
          <strong>Supply Chain Optimization:</strong> Predicts demand and
          optimizes inventory, reducing waste and improving delivery times.
        </p>
        <p className="p-aicontent">
          <strong>Robotics in Manufacturing:</strong> Automates repetitive
          tasks, increasing production efficiency and worker safety.
        </p>
      </>
    ),
    imageUrl: product,
    link: "/productautomation",
  },
  {
    title: "Financial",
    text: (
      <>
        <p className="p-aicontent">
          <strong>Algorithmic Trading:</strong> Uses AI algorithms to execute
          trades at the best possible prices based on market conditions.
        </p>
        <p className="p-aicontent">
          <strong>Fraud Detection:</strong> Analyzes transaction patterns to
          identify and prevent fraudulent activities in real-time.
        </p>
        <p className="p-aicontent">
          <strong>Personalized Banking:</strong> Offers tailored financial
          advice and product recommendations based on individual customer data.
        </p>
      </>
    ),
    imageUrl: financial,
    link: "/financial",
  },
];

const AISectorComponent = () => {
  return (
    <div className="w-100">
      <ServiceHeader title="Gen AI" />
      <div style={{ padding: "10px 20%" }}>
        {data.map((item, index) => (
          <Link
            to={item.link}
            key={index}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div
              key={index}
              className={`row sector-row ${
                index % 2 === 0 ? "" : "flex-row-reverse"
              }`}
            >
              <div className="col-lg-4 sector-image">
                <img
                  src={item.imageUrl}
                  alt={item.title}
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-8 sector-text">
                <h4 className="sector-title">{item.title}</h4>
                <p className="sector-paragraph">{item.text}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AISectorComponent;
