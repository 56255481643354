import React from "react";
import { NavLink } from "react-router-dom";
import { useActiveMenu } from "../auth/ActiveMenuContext";
import NavBar from "./NavBar";
import Header from "../component/Header";
import { routes } from "../routes/routes";
import "./SideBar.css";
import Footer from "./Footer";

const SideBar = ({ children }) => {
  const { activeMenu, setActiveMenu } = useActiveMenu();

  return (
    <div className="containerside" style={{ margin: "0", padding: "0" }}>
      <section className="routes">
        {routes.map((route, index) => (
          <NavLink
            to={route.path}
            key={index}
            className={`link ${activeMenu === route.name ? "activeMenu" : ""}`}
            activeClassName="active"
            onClick={() => {
              setActiveMenu(route.name);
              localStorage.setItem("activeMenu", route.name);
            }}
          >
            <div className="icon">{route.icon}</div>
            <span className="name">{route.name}</span>
          </NavLink>
        ))}
      </section>
      <div className="content-area">
        <Header />
        <NavBar />
        <main>{children}</main>
        <Footer />
      </div>
    </div>
  );
};

export default SideBar;
