import React from "react";
import Myimage from "../../assets/images/PUBLICSECTOR1.jpg";

import "../../views/PageContent.css";
import ContactUs from "../../component/contactus";

import ServiceHeader from "../../component/ServiceHeader";
const Retail = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="RETAIL" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div className="imgg">
            <img src={Myimage} className="Myimg" alt="legal" />
          </div>

          <div className="row middle">
            <p>
              <br></br>
              The Retail industry has seen the emergence of new power retailers
              owing to the growth of e-commerce and social media, with Amazon
              Web Services alone generating $7.88 billion in 2015. The
              combination of the two brought along another level of promotion
              and distribution channels which the traditional retail industry
              had not anticipated. Unlike operations that traditional retailers
              have been following for a very long time, e-retailers (also
              referred to as e-tailers) have become consumers of cloud
              technology for a while.
            </p>
            <br></br>
            <p>
              <h5>THE PRESENT SITUATION IN THE RETAIL INDUSTRY</h5>
              It is common knowledge that the retail industry across the globe
              is under severe pressure to keep their businesses running. Of all
              retailers, it has been store operators who have largely suffered
              due to the growth of online shopping as well as discount stores to
              an extent.
              <br></br>According to a recent report by Marketwatch, Sports
              Authority and Vestis Retail Group, the operator of Sport Chalet
              and Eastern Mountain Sports, declared bankruptcy as they announced
              the closing of 196 stores.
              <br></br>Sports is not the only affected sector. Hancock Fabrics,
              a retailer since 1957, announced that it would close all of its
              185 stores and file for bankruptcy.
              <br></br>In office supplies: Staples announced earlier this year
              it had plans to close 50 stores in North America. This is not the
              first time Staples had to resort to this step. In the last two
              fiscal years, Staples has closed 242 locations in the U.S. and
              Canada.
              <br></br>However, while some retailers have seen a consistent drop
              in their business, some have seen growth despite encountering the
              same circumstances.
              <br></br>Home Depot is one such retailer and perhaps one of those
              few who is not only surviving this obstacle but successfully
              overcoming it. Home Depot increased its store count and their
              business has risen from $5.8 billion 10 years ago to $7 billion in
              2015.
              <br></br>2016 E-commerce statistics released by ReadyCloud said
              nearly 80% of the entire US population makes purchases online.
              <br></br>However, Market experts believe that discount stores and
              e-commerce are not the only ones to blame for a consistent fall of
              many retailers, they also blamed poor management.
            </p>
            <br></br>
            <p>
              <h5>HOW CAN CLOUD COMPUTING BENEFIT THE RETAIL INDUSTRY</h5>
              <br></br>Cloud technology could be the driving force with the
              potential to change the face of the retail industry and hence
              possibly save it from dying. Some benefits cloud adoption brings
              to the table are:
              <br></br>
            </p>
            <p>
              <h5>COST EFFECTIVE OPERATIONS</h5>
              Cloud technology has been the preferred solution due to the cost
              at which the services are provided, as they are generally much
              lower when compared to in-house operations.
              <br></br>A business can stand to save a significant amount of
              money as they do not need to purchase, update or manage systems.
              This automatically cuts down on hiring employees who would perform
              these duties in an in-house environment.
              <br></br>
            </p>
            <p>
              <h5>FLEXIBILITY AND SCALABILITY</h5>
              The cloud-based model offers cost flexibility thanks to its varied
              pricing options, allowing business owners to increase or decrease
              their computing usage as circumstances warrant. Particularly, it
              offers scalability at times of peak demand, so stores don’t have
              to purchase additional equipment – an aspect that an in-house
              infrastructure arrangement can’t match.
              <br></br>
            </p>
            <p>
              <h5>SMOOTH SHOPPING EXPERIENCE</h5>
              Customers can connect to stores 24/7 thanks to their online
              presence and smartphone applications. A store can keep updating
              its customers with promotions and deals, coupon codes, special
              membership deals, etc.
              <br></br>Customers can be greeted with a welcome message or deals
              of the day messages as soon as they walk in the store. In fact,
              some existing stores are already using this technology. It
              provides a very personalized experience for the customer.
              <br></br>Better communication & collaboration between departments
              <br></br>Stores can benefit from effective tools in order to
              communicate with colleagues and employees from another department
              or from another location. Effective collaboration results in
              streamlined business management and reduces the scope of
              mismanagement or miscommunication.
              <br></br>
            </p>
            <p>
              <h5>IMPROVED POS</h5>
              Point of sale (POS) systems are now steadily moving from cash and
              cards to smartphones and tablets. NFC technology and applications
              allow customers to make payments directly from their devices.
              <br></br>Cloud technology ensures that a customer experiences a
              smooth transaction process, something which can prove to be quite
              complicated when operating through traditional means.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Retail;
