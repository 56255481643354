import React from "react";
import ServiceHeader from "../../component/ServiceHeader";
import ContactUs from "../../component/contactus";
import "../../views/PageContent.css";
import Myimage from "../../assets/AI images/Autoscribe.jpg";

const Autoscribe = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="AUTOSCRIBE" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div className="imgg">
            <img src={Myimage} alt="autoscribe"></img>
          </div>
          <div className="row middle" >
            <h2>Autoscribe</h2>
            <p>
              Autoscribe is an advanced transcription software that seamlessly
              converts speech to text, revolutionizing the way you manage
              documentation. By leveraging state-of-the-art artificial
              intelligence, Autoscribe optimizes and elevates your documentation
              process, allowing you to focus on your core tasks. Our innovative
              Gen AI services handle the complexities of data entry, ensuring
              efficiency and accuracy in every transcription.
            </p>

            <h4>Speech Recognition</h4>
            <p>
              Experience seamless conversion of speech into text in real-time.
              Our cutting-edge speech recognition technology ensures accurate
              and efficient documentation, helping you maintain compliance and
              focus on delivering high-quality care. Whether in meetings,
              consultations, or everyday note-taking, Autoscribe makes
              transcription effortless and precise.
            </p>

            <h4>Natural Language Processing (NLP)</h4>
            <p>
              Our NLP capabilities continuously evolve, learning from each
              interaction to improve accuracy over time. By adapting to specific
              terminologies and context, our system ensures that your
              documentation is not only correct but also contextually relevant.
              Autoscribe's NLP engine helps reduce the time spent on manual
              corrections and boosts overall productivity.
            </p>

            <h4>Integration with EHRs</h4>
            <p>
              Autoscribe seamlessly integrates with Electronic Health Records
              (EHRs), automating the input of patient information. This
              integration minimizes manual errors, saves valuable time, and
              ensures that patient records are always up-to-date. With
              Autoscribe, healthcare professionals can trust that their data is
              managed with the highest standards of accuracy and security.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Autoscribe;
