import React from "react";
import "../PageContent.css";
import img from "../../assets/images/carees.jpg";
import ServiceHeader from "../../component/ServiceHeader";
import ContactUs from "../../component/contactus";
const Careers = () => {
  return (
    <div className="wrapper">
      <ServiceHeader title="CAREERS" />
      
        {/* <div className="row4">h</div> */}

        <div className="row mp">
          <div className="col-sm-3 mpc">
            <ContactUs />
          </div>
          <div className="col-sm-1"></div>
          <div className="col-sm-8">
            <div className="imgg">
              <img align="right" src={img} />
            </div>
            <div>
              <p>
                <p>
                  In general, for all positions, we will require the following
                  basic skillset:
                </p>
                <p>
                  We are looking for passionate individuals with the fire to
                  take up challenges and solve them for our clients. If you are
                  someone who has the appetite for high-energy level issues
                  reported your way, and are ready to focus your energy on
                  solving them to the satisfaction of the clients, this is for
                  you.
                </p>
                There are a few positions for which we are always hiring. Some
                of them are:
                <ul>
                  <li>Level 1 Support Team Member</li>
                  <li>Level 2 AWS Engineer (with AWS Certification)</li>
                  <li>Level 2 Azure Engineer (with Azure Certification)</li>
                  <li>Level 3 AWS Architect (with AWS Certification)</li>
                  <li>Level 3 Azure Architect (with Azure Certification)</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
  
  );
};

export default Careers;
