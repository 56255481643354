import React from "react";
import ServiceHeader from "../../component/ServiceHeader";
import ContactUs from "../../component/contactus";
import "../../views/PageContent.css";
import Myimage from "../../assets/AI images/Insurance.jpg";

const Insurance = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="INSURANCE" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
        <div className="imgg">
            <img src={Myimage} alt="Insurance" />
          </div>
          
          <div className="row middle">
            <h2>Insurance</h2>
            <h4>Claims Processing</h4>
            <p>
              Automate the claims process from initiation to review,
              significantly reducing processing time and minimizing the risk of
              fraud. Our AI-driven solutions ensure that claims are handled
              efficiently and accurately, improving overall customer
              satisfaction. By utilizing machine learning algorithms, our system
              can quickly verify claim details, detect anomalies, and expedite
              approval processes, leading to faster settlements and happier
              customers.
            </p>

            <h4>Personalized Policies</h4>
            <p>
              Leverage data analytics to offer customized insurance policies
              tailored to individual risk profiles. Our advanced AI algorithms
              analyze vast amounts of data, including demographic information,
              lifestyle choices, and historical claims, to create policies that
              meet the unique needs of each customer. This personalized approach
              ensures optimal coverage and competitive pricing, enhancing
              customer satisfaction and loyalty.
            </p>

            <h4>Customer Service Bots</h4>
            <p>
              Provide 24/7 customer support with our intelligent customer
              service bots. These AI-powered bots handle inquiries, guide users
              through the insurance process, and provide real-time assistance.
              Whether it's answering common questions, helping with policy
              adjustments, or assisting with claims, our bots ensure that
              customers always have access to the help they need. This
              continuous availability not only improves the customer experience
              but also reduces the workload on human agents.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Insurance;
