import React, { createContext, useState, useContext } from "react";

const ActiveMenuContext = createContext();

export const useActiveMenu = () => useContext(ActiveMenuContext);
export const ActiveMenuProvider = ({ children }) => {
  const [activeMenu, setActiveMenu] = useState(
    window.location.pathname === "/"
      ? "Home"
      : localStorage.getItem("activeMenu")
  ); // Default active menu
  return (
    <ActiveMenuContext.Provider value={{ activeMenu, setActiveMenu }}>
      {children}
    </ActiveMenuContext.Provider>
  );
};
