import React from "react";
import Myimage from "../../../assets/images/database-assessment.png";

import "../../../views/PageContent.css";
import ServiceHeader from "../../../component/ServiceHeader";
import ContactUs from "../../../component/contactus";

const databaseassessment = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="DATABASE ASSESSMENT" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>

        <div className="col-sm-8">
          <div className="imgg">
            <img src={Myimage} className="Myimg" alt="security" />
          </div>

          <div className="middle">
            <h4>WHY DO A CLOUD DATABASE MIGRATION?</h4>
            <p>
              Before we begin, let us consider the scenarios in which cloud
              database migration would be a viable option:
              <ul>
                <li>The ability to manage databases in-house is inadequate</li>
                <li>IT is not a central functional unit</li>
                <li>You’re an SME and need to cut initial capex costs</li>
                <li>
                  You are working with new applications or developing one, and
                  want to try the cloud as a testing environment
                </li>
                <li>
                  Moving to the cloud for your disaster recovery (DR) backup,
                  and using it as a trial run to identify issues and hurdles to
                  database migration
                </li>
              </ul>
              The key advantages to cloud database migration are availability,
              scalability, reliability, and cost. The cloud infrastructure is
              scalable, and no capex investment is needed. Business are
              generally very open to database migration if security concerns are
              addressed satisfactorily.
              <br></br>
              Although database migration in isolation (without migrating
              applications) is not impossible to accomplish, it may not be very
              feasible. When your application resides in-house and your database
              runs on an external server, life will not be easy. The two
              networks need to collaborate seamlessly to provide fast and
              optimum functioning. The process must work in most instances,
              otherwise it will not perform any better than it did in-house.
              That is also why it is recommended to migrate the entire set-up
              onto the cloud.
            </p>
            <br></br>
            <p>
              <h4>POINTERS FOR A SUCCESSFUL DATABASE MIGRATION</h4>
              <ol>
                <li>
                  Assess database size: Database sizing will determine what
                  hardware is required, and how much storage and what instance
                  will be needed after migration. This can be undertaken by the
                  internal IT team itself.{" "}
                </li>
                <li>
                  {" "}
                  Test applications before data migration: The applications the
                  service provider uses to connect to the database have to be
                  fine-tuned to the applications that will use the database.
                  Applications running on the cloud database should also be
                  compatible with cloud infrastructure, and provide better
                  performance than the in-house set-up. The cloud datacenters
                  may not be in the vicinity, and there may be high latency
                  issues. Applications should be able to perform in such
                  situations. Raise the issue with your service provider, and
                  make sure you’re both on the same page..
                </li>
                <li>
                  Data confidentiality is a deal maker: To begin with, you might
                  want to migrate only those databases and applications which
                  are not mission critical. First migrate those databases which
                  can be hosted in environments that may not be trusted.{" "}
                </li>
                <li>
                  Design the service level agreement (SLA) document carefully:
                  There are applications which will require 99.99% up-time. Make
                  sure scheduled down-times don’t interfere with your business
                  needs.{" "}
                </li>

                <li>
                  Ensure scalability: The main attraction of a database
                  migration to the cloud is immediate scalability. Services and
                  infrastructure should ideally be scalable on the fly. Yes,
                  that will have to be negotiated with the provider. Keep the
                  service vendor in the loop about your business growth plans.
                </li>
                <li>
                  Mind your OS: Finding the operating system (OS) that works
                  well with your databases is crucial. For example, Oracle is
                  available for Linux as well as Windows. Although both serve
                  the same purpose, there will be a huge difference
                  performance-wise. Check for the same version of the OS on the
                  cloud.
                </li>
                <li>
                  Eliminating garbage will reduce costs: Cleansing of data
                  becomes very important as costing depends on the size of the
                  data. As database size grows, costs will also go up. Make sure
                  to eliminate garbage data from the database before migrating
                  it.
                </li>
              </ol>
            </p>

            <p>
              <h4>WAYS TO OVERCOME HASSLES</h4>
              During your cloud database migration you may have to deal with
              performance and security issues. Here is how this can be tackled
              with ease.
            </p>

            <ol>
              <li>
                Security: Your public cloud host could potentially be untrusted.
                It can reside anywhere, and there is no control of the customer
                over this aspect. One way out is to implement a private cloud.
                Factor this into your SLA. The provider’s job is to provide the
                infrastructure, make the data available, and adhere to the
                security policies of the agreement. The data massaging or
                cleansing activity must be undertaken in-house because in
                principle, the provider should not view or process any data from
                your database.
              </li>
              <li>
                Mind your OS: Finding the operating system (OS) that works well
                with your databases is crucial. For example, Oracle is available
                for Linux as well as Windows. Although both serve the same
                purpose, there will be a huge difference performance-wise. Check
                for the same version of the OS on the cloud.
              </li>
              <li>
                Eliminating garbage will reduce costs: Cleansing of data becomes
                very important as costing depends on the size of the data. As
                database size grows, costs will also go up. Make sure to
                eliminate garbage data from the database before migrating it.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default databaseassessment;
