import React from "react";
import Myimage from "../../../assets/images/gcpsecurity.jpg";

import "../../../views/PageContent.css";
import ServiceHeader from "../../../component/ServiceHeader";
import ContactUs from "../../../component/contactus";

const gcpsecurity = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="GCP SECURITY" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>

        <div className="col-sm-8">
          <div className="imgg">
            <img src={Myimage} className="Myimg" alt="security" />
          </div>

          <div className="middle">
            <h2>GCP SECURITY</h2>
            <p>
              Google Cloud Platform (GCP), offered by Google, is a suite of
              cloud computing services that runs on the same infrastructure that
              Google uses internally for its end-user products, such as Google
              Search, Gmail, file storage, and YouTube. <br></br>
              Google Cloud’s functions as a service (FaaS) offering, provides a
              serverless execution environment for building and connecting cloud
              services. With Cloud Functions you write simple, single-purpose
              functions that are attached to events emitted from your cloud
              infrastructure and services. Your function is triggered when an
              event being watched is fired. Your code executes in a fully
              managed environment. There is no need to provision any
              infrastructure or worry about managing any servers.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default gcpsecurity;
