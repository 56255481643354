import ServiceHeader from "../../component/ServiceHeader";
import "../PageContent.css";
import ContactUs from "../../component/contactus";
import img from "../../assets/images/gcpmanagedser.jpg";
const GcpManagedSer = () => {
  return (
    <div className="wrapper">
   
      <ServiceHeader title="GCP MANAGED SERVICES" />
      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div className="imgg">
            <img align="right" src={img} />
          </div>
          <div className="row middle">
            <h2>GCP Managed Service</h2>
            <p>
              <br />
              <h4>Google Cloud Platform</h4>
              <b>Managed Services</b>
              <br />
              Predictable, Proven Solutions from GCP Experts
              <br />
              <br />
              5M-SST is a leader in managing Google Cloud Platform environments
              at any scale.
              <p>
                We provide you with a team of certified GCP experts to take
                operational tasks off your plate. As a Google Cloud Platform
                Partner, our services provide leading-edge management and
                optimization practices to ensure security, reduce Google Cloud
                costs, and improve availability.
              </p>
              <p>
                By acting as an extension of your team, whether in a full or
                partial managed services capacity, 5M-SST opens the door to
                innovation by delivering a dependable environment for your most
                critical business functions.
              </p>
              <b>Always-On Support</b>
              <p>
                Enjoy complete visibility and control when you need it most with
                24/7 support, monitoring, and ticketing.
              </p>
              <b>Dedicated Experts</b>
              <p>
                Back your team with 5M-SST Google Cloud certified experts as
                well as a dedicated Client Success Manager.
              </p>
              <b>Continuous Optimization</b>
              <p>
                Take the complexity out of your cloud environment through
                continuous, expert-driven improvements to your GCP environment.
              </p>
              <b>Cloud Cost Optimization</b>
              <p>
                Simplify your billing with predictable, affordable cloud costs
                for your single or multi-cloud environment.
              </p>
              <b>Multi-Cloud Masters</b>
              <p>
                Back your multi-cloud environment with proven expertise. Qwinix
                holds partner-status with each of the public clouds (AWS, GCP,
                and Azure).
              </p>
              <b>Security and Compliance</b>
              <p>
                Enjoy peace of mind knowing our team will detect, respond, and
                prevent gaps in cloud security and compliance.
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GcpManagedSer;
