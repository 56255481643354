import React from "react";
import Myimage from "../../../assets/images/Data-Centre-Assesment.jpg";

import "../../../views/PageContent.css";
import ServiceHeader from "../../../component/ServiceHeader";
import ContactUs from "../../../component/contactus";

const datacenterassessment = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="DATACENTER ASSESSMENT" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div className="imgg">
            <img src={Myimage} className="Myimg" alt="security" />
          </div>

          <div className="middle">
            <p>
              <h3>DATA CENTERS ASSESSMENT</h3>
              <b>Cloud and Data Center Assessments</b>
              <br></br>
              Whether you’re looking to optimize your resources, to reduce your
              data center risk, or to prepare for hybrid computing—or to
              accomplish all three—our cloud and data center assessments provide
              actionable insights about your environment so you can deliver
              better a digital experience for your users.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default datacenterassessment;
