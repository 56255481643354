import React from "react";
import { useActiveMenu } from "../auth/ActiveMenuContext";
import { useNavigate } from "react-router-dom";
import "./NavBar.css";
import sidebarRoutes from "../routes/SideBarRoutes";
import { Outlet } from "react-router-dom";
import { routes } from "../routes/routes";

const NavBar = () => {
  const { activeMenu, setActiveMenu } = useActiveMenu();
  const navigate = useNavigate();

  // Function to render nav items based on the active menu
  const renderNavItems = (navItems, applySpecialMargin = false) => {
    return navItems.map((item, index) => (
      <li
        key={index}
        className={`nav-item ${applySpecialMargin ? "special-margin" : ""}`}
      >
        <a
          className="nav-link navbar-text"
          href={item.href}
          // onClick={(e) => renderSubNavItems(item.subRoutes)}
          onClick={(e) => {
            e.preventDefault();
            routes.find((content) => {
              return content.name === item.name;
            }) !== undefined && setActiveMenu(item.name);
            navigate(item.href);
          }}
        >
          <strong>{item.name}</strong>
        </a>
        {item.subRoutes && renderNestedSubNavItems(item.subRoutes)}
      </li>
    ));
  };

  // Render nested sub-navigation items if available
  const renderNestedSubNavItems = (subRoutes) => {
    return (
      // <div className="dropdown">
      <ul className="dropdown-menu">
        {subRoutes.map((nestedItem, nestedIndex) => (
          <li key={nestedIndex}>
            <a
              className="dropdown-item"
              href={nestedItem.href}
              onClick={(e) => {
                e.preventDefault();
                navigate(nestedItem.href);
              }}
            >
              {nestedItem.name}
            </a>
          </li>
        ))}
      </ul>
      // </div>
    );
  };

  return (
    <div>
      {console.log("Active link:-", activeMenu)}
      <nav className="navbar navbar-expand-lg bg-custom">
        <div
          className="collapse navbar-collapse justify-content-end head-nav p-0 m-0 h-20"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ml-auto">
            {/* Conditional rendering based on activeMenu */}
            {activeMenu === "Home" &&
              renderNavItems(sidebarRoutes.homeNavItems)}
            {activeMenu === "GenAI" &&
              renderNavItems(sidebarRoutes.genaiNavItems)}
            {activeMenu === "Manage Cloud" &&
              renderNavItems(sidebarRoutes.manageCloudNavItems)}
            {activeMenu === "Migrations" &&
              renderNavItems(sidebarRoutes.migrationNavItems)}
            {activeMenu === "Security, Compliance, and Assessments" &&
              renderNavItems(sidebarRoutes.securityComplianceNavItems, true)}
            {activeMenu === "Industry" &&
              renderNavItems(sidebarRoutes.industryNavItems)}
            {activeMenu === "Professional Services" &&
              renderNavItems(sidebarRoutes.homeNavItems)}
            {activeMenu === "RPO" && renderNavItems(sidebarRoutes.homeNavItems)}
            {activeMenu === "Development & Testing Services" &&
              renderNavItems(sidebarRoutes.developmentTestingNavItems)}
            {activeMenu === "Enterprise Applications" &&
              renderNavItems(sidebarRoutes.homeNavItems)}
            {activeMenu === "About Us" &&
              renderNavItems(sidebarRoutes.aboutUsNavItems)}
            {activeMenu === "Contact Us" &&
              renderNavItems(sidebarRoutes.homeNavItems)}
          </ul>
        </div>
      </nav>
      <Outlet />
    </div>
 );
};

export default NavBar;
