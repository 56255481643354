import React from "react";
import Myimage from "../../../assets/images/managedsecurity.png";

import "../../../views/PageContent.css";
import ServiceHeader from "../../../component/ServiceHeader";
import ContactUs from "../../../component/contactus";

const managedsecurity = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="MANAGED SECURITY" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>

        <div className="col-sm-8">
          <div className="imgg">
            <img src={Myimage} className="Myimg" alt="security" />
          </div>

          <div className="middle">
            <h2>MANAGED SECURITY</h2>
            <p>
              Elite cyber threat intelligence combined with security expertise
              protects devices and data and provides continuous security
              monitoring and operational administration of managed devices to
              safeguard investments and meet compliance regulations. Prevent the
              threats you can and leverage elite cyber threat intelligence and
              global visibility to detect and respond to the threats you can’t
              prevent.
              <br></br>
              You can trust our 24X7 Dedicated Cyber managed security Operations
              center on any platform. Our SOC is Compliant with Global
              Regulatory standards and data sovereignty laws. 6000 Servers
              Protected.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default managedsecurity;
