import ServiceHeader from "../../component/ServiceHeader";
import "../PageContent.css";
import ContactUs from "../../component/contactus";
import img from "../../assets/images/database_migration_shutterstock_hanss (1).jpg";
const DatabaseMigrations = () => {
  return (
    <div className="wrapper">
      <ServiceHeader title="DATABASE MIGRATIONS" />
      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div className="imgg">
            <img align="right" src={img} />
          </div>
          <div className="row middle">
            <h2>DISASTER RECOVERY</h2>
            <p>
              <br />
              <b>Our Database Services</b>
              <p>
                Migrating your on-premises database to AWS or Azure can reduce
                the burden of database management, significantly cut licensing
                costs, and enable you to add storage capacity easily on demand.
                5Minute-SST can help you replatform your database and migrate to
                a fully-managed cloud service.
              </p>
              <b>Migrate to a Cloud Database Platform</b>
              <p>
                Design and build a secure AWS or Azure cloud environment that
                includes a high-performance, fully-managed cloud database.
                Manage your Cloud Database
              </p>
              <p>
                Get 24×7 support for your database from a team of experienced
                DBAs and cloud experts.
              </p>
              Modernize your Data Analytics
              <p>
                Create a new Data Lake, Data Warehouse or Business Intelligence
                solution in AWS or Azure.
              </p>
              <b>Database Migration Services</b>
              <p>
                Migrating your on-premises database to AWS or Azure can reduce
                the burden of database management, significantly cut licensing
                costs, and enable you to add storage capacity easily on demand.
                5Minute-SST can help you replatform your database and migrate to
                a fully-managed cloud service.
              </p>
              <b>Data Analytics Services</b>
              <p>
                One of the most powerful advantages of storing your data in the
                cloud is the ability to leverage analytics and business
                intelligence. 5Minute-SST can create new data analytics from
                scratch or migrate and modernize your existing systems.
              </p>
              <ul>
                <li>
                  Create a new Data Lake, Data Warehouse, and/or Business
                  Intelligence solution in AWS
                </li>
                <br />
                <li>
                  Assess and improve Data Integrity across myriad sources for
                  use in analytics
                </li>
                <li>
                  Analyze and implement ETL (Extract, Transform, Load) systems
                  to aggregate your data
                </li>
                <li>
                  Customize the Presentation Layer of your new analytics system
                </li>
                according to your Key
              </ul>
              <b>Performance Indicators</b>
              <br />
              Source Platforms
              <ul>
                <li>Oracle</li>

                <li>SQL Server Data Warehouse</li>

                <li>IBM Netezza</li>
              </ul>
              Destination Platforms
              <ul>
                <li>Amazon Kinesis</li>

                <li>Amazon Glue</li>

                <li>AWS Data Pipeline</li>

                <li>Amazon Redshift</li>

                <li>AWS Snowflake</li>

                <li>Azure Synapse Analytics</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DatabaseMigrations;
