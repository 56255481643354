import React from "react";
import Myimage from "../../assets/images/healthcare-mobile-apps-development.png";

import "../../views/PageContent.css";
import ContactUs from "../../component/contactus";

import ServiceHeader from "../../component/ServiceHeader";
const Healthcare = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="HEALTHCARE" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div className="imgg">
            <img src={Myimage} alt="industry" />
          </div>

          <div className="row middle">
            <h2>Fast-Track Your Health IT Solutions with 5Minute-SST/</h2>
            <p>
              5Minute-SST solves two of health IT’s most difficult
              challenges—EHR integration and cloud compliance. Our expertise
              provides customers the confidence and infrastructure to scale
              quickly and securely.
              <br></br>
            </p>
            <p>
              {" "}
              <h4>Integration</h4>
              Delivering innovative health IT solutions requires data
              integration between multiple electronic health records (EHRs),
              clinical systems, and third-party applications. Integration is
              essential, but it can quickly veer into an obstacle course that
              takes your team off track.
              <br></br>5Minute-SST solves this for you by removing the
              integration roadblocks so your IT team can stay on course to
              handle what matters most.
              <br></br>Our 5Minute-SST Integrate solution enables health data
              integration from any source to any destination, in any format.
            </p>
            <br></br>
            <p>
              <h4>Cloud Compliance</h4>
              In today’s world compliance is a basic requirement when working
              with health systems. Check that box faster with 5Minute-SST.
              <br></br>Compliance isn’t a one and done activity. Get compliant
              in the cloud (quickly and easily) without the continued headaches
              of managing the aspects of managing compliance yourself.
              <br></br>Customer after customer has become HITRUST compliant with
              5Minute-SST. No matter how unique your compliance needs – you can
              be audit-ready.
              <br></br>As your needs grow and evolve, we’re there to support
              you. We will continue to offer new compliant cloud services based
              on customer requests and market need, as well as advise while you
              venture into using new cloud services.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Healthcare;
