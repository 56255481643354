import axios from "axios";

const sendEmail = async ({
  application_name,
  env_type,
  subject,
  message,
  html_message,
  receiver_addresses,
  cc_addresses,
  bcc_addresses,
  attachments,
}) => {
  try {
    const formData = new FormData();
    formData.append("application_name", application_name || "");
    formData.append("env_type", env_type || "");
    formData.append("subject", subject);
    formData.append("message", message);
    formData.append("html_message", html_message || "");

    if (receiver_addresses && receiver_addresses.length > 0) {
      receiver_addresses.forEach((address) => {
        formData.append("receiver_addresses[]", address);
      });
    }

    if (cc_addresses && cc_addresses.length > 0) {
      cc_addresses.forEach((address) => {
        formData.append("cc_addresses[]", address);
      });
    }

    if (bcc_addresses && bcc_addresses.length > 0) {
      bcc_addresses.forEach((address) => {
        formData.append("bcc_addresses[]", address);
      });
    }

    if (attachments && attachments.length > 0) {
      attachments.forEach((attachment) => {
        formData.append("attachments", attachment);
      });
    }

    const response = await axios.post(
      "https://app-acs-dev-eastus.azurewebsites.net/send_email",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.status === 200 ? true : false;
  } catch (error) {
    console.error("Error sending email:", error);
    return false;
  }
};

export default sendEmail;
