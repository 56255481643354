import React from "react";
import Myimage from "../../../assets/images/azuresecurity.jpg";
import "../../../views/PageContent.css";
import ServiceHeader from "../../../component/ServiceHeader";
import ContactUs from "../../../component/contactus";

const azuresecurity = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="AZURE SECURITY" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>

        <div className="col-sm-8">
          <div className="imgg">
            <img src={Myimage} alt="security" />
          </div>

          <div className="row middle">
            <h2>AZURE SECURITY</h2>
            <p>
              <br></br>
              Protect data, apps, and infrastructure quickly with built-in
              security services in Azure that include unparalleled security
              intelligence to help identify rapidly evolving threats early—so
              you can respond quickly. Implement a layered, defense-in-depth
              strategy across identity, data, hosts, and networks.
              <br></br>
              <br></br>
              We combine 24×7 expert support with our world-className management
              platform, to help you achieve operational excellence on Microsoft
              Azure. With our Managed Azure Service, you can focus on
              revenue-generating tasks and rely on our Certified Azure team to
              manage your infrastructure.
              <br></br>
              <br></br>
              Keep your application safe and monitor security across on-premise
              and cloud workloads with Azure security monitoring. Identify and
              correct vulnerabilities before they can be exploited and stay
              ahead of would-be attackers. 5M-SST Azure Security Monitoring and
              Management Services will:
              <br></br>
              <ul>
                <li>Enable centralized management of security policies.</li>
                <li>
                  Implement continuous security assessment and DDoS attack
                  monitoring.
                </li>
                <li>Ensure actionable alerts and recommendations.</li>
                <li>
                  Leverage cloud security monitoring services to monitor
                  on-premise and cloud workloads and keep your data safe
                </li>
                <li>Implementation of CI/CD process using Azure DevOps</li>
                <li>
                  Enabling application telemetry collection for monitoring,
                  troubleshooting, and usage analytics
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default azuresecurity;
