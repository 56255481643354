import ServiceHeader from "../../component/ServiceHeader";
import "../PageContent.css";
import ContactUs from "../../component/contactus";
import img from "../../assets/images/Hybrid-Cloud-Header-Image.png";
const HybridCloud = () => {
  return (
    <div className="wrapper">
      <ServiceHeader title="HYBRID CLOUD" />

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div className="imgg">
            <img align="right" src={img} />
          </div>
          <div className="row middle">
            <h2>Hybrid Cloud</h2>
            <p>
              <br />
              <b>Multi-Platform Strategy & Operations</b>
              <br />
              <p>
                Your mission-critical applications need to be secure and
                available regardless of your cloud platform choice. 5Minute-SST
                has decades of experience helping customers develop tailored
                solutions to meet the challenges of today with a design for
                future success.
              </p>

              <p>
                <b>Get Multi-Cloud Visibility with 5Minute-SST Pulse</b>
                <p>
                  5Minute-SST Pulse consolidates billing, ticketing, security,
                  and budget management into an easy-to-use central portal.
                </p>
                <ul>
                  <li>
                    Customizable monitoring compatible with AWS CloudWatch,
                    Azure, Datadog, and New Relic
                  </li>
                  <li>
                    Easily configure complex AWS tools like Macie, Trusted
                    Advisor, and Systems Manager
                  </li>
                  <li>
                    Scan for best practices, security threats, and console-level
                    configurations
                  </li>
                  <li>
                    {" "}
                    Manage service tickets, budget dashboard, and security
                  </li>
                  metrics
                </ul>
              </p>
              <p>
                <b>Connect Your Datacenter to AWS and Azure</b>
                <p>
                  Whether you are establishing your cloud-readiness,
                  consolidating your datacenter, or need to continue to host
                  specific applications on the private cloud or colocation
                  facility, 5Minute-SST can help you architect a secure,
                  reliable environment to meet your needs.
                </p>
                <ul>
                  <li>AWS Direct Connect, AWS Outposts, Azure ExpressRoute</li>

                  <li>
                    Global Datacenter Footprint: US East & West, EMEA, and APAC
                  </li>

                  <li>
                    Compliance Expertise: HIPAA, HITRUST, PCI DSS, SOC 1, SOC 2,
                    ISO 27001, GDPR
                  </li>

                  <li>VMWare Migration & Management</li>
                  <li>Cloud-Readiness Planning & Cost Management</li>
                </ul>
              </p>
              <p>
                <b>Disaster Recovery</b>
                <br />
                5Minute-SST business continuity services are designed to prevent
                downtime and maintain your mission-critical applications are
                always available and
                <br />
                • BaaS & MRaaS Solutions
                <br />
                • Compliance & Audit Support
                <br />
                • Rapid Response & Recovery
                <br />• Data Center & Direct Connect Support
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HybridCloud;
