import React from "react";
import Myimage from "../../../assets/images/securityass.jpg";
import Myimage1 from "../../../assets/images/Security-Assessment1.jpg";

import "../../../views/PageContent.css";
import ServiceHeader from "../../../component/ServiceHeader";
import ContactUs from "../../../component/contactus";
const securityassessment = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="SECURITY ASSESSMENT" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>

        <div className="col-sm-8">
          <div className="imgg">
            <img src={Myimage} className="Myimg" alt="security" />
          </div>

          <div className="middle">
            <p>
              <h2>SECURITY ASSESSMENT</h2>
              <h3>Ensuring You Cloud Infrastructure is Secure</h3>
              <br></br>Are you hosting in the Cloud? Security challenges in the
              Cloud are different than those that exist on prem, but the overall
              processes to build and maintain a secure environment are the same.
              What would be the impact if your database was compromised? What
              consequences would you face if a malicious outsider gained access
              to your cloud environment? How can you be sure there isn’t a
              misconfiguration in the infrastructure? Just as you would do a
              security assessment on prem, ensuring cloud security must begin
              with a Cloud Security Assessment.
            </p>
            <br></br>
            <p>
              <h3>The 5MINSST Cloud Security Assessment includes:</h3>
              <ul>
                <li>Identifying cloud security risks.</li>
                <li>
                  Performing a cloud security audit to document current controls
                  and provide visibility into the strengths and weaknesses of
                  current systems.
                </li>
                <li>
                  Assessing gaps in current capabilities that may weaken cloud
                  security in recommending technology and services to address
                  them.
                </li>
                <li>
                  Assessing security maturity by benchmarking current controls
                  and practices against leading methods and standards.
                </li>
                <li>
                  Performing a cloud security assessment of the effectiveness of
                  current policies and their alignment with business goals.
                </li>
              </ul>
              We also generate reports on compliance with the 5MINSST Framework,
              AWS Well Architected Framework (for AWS environments), and the
              Microsoft Azure Best Practices (for Azure environments).
            </p>
            <img
              src={Myimage1}
              className="Myimg"
              alt="security"
              style={{ height: "60%", width: "60%" }}
            />

            <p>
              {" "}
              <h2>Need to see it in action?</h2>
              5MINSST has a Proof of Concept Lab providing a secure,
              state-of-the-art proof of concept testing infrastructure, and
              optional access to our Professional Services experts.
            </p>
            <p>
              {" "}
              <h2>10 Step Cloud Application Security Audit Checklist</h2>
              What is cloud application security? It is a series of defined
              policies, processes, controls, and technology governing all
              information exchanges that happen in collaborative cloud Software
              as a Service (SaaS) applications like Microsoft Office 365 and
              Google G Suite.
              <br></br>
              As your school district moves more information and activity to the
              cloud, your perimeter security safeguards become less effective.
              More IT and security professionals are opting to secure cloud
              storage by deploying a zero trust security model. This checklist
              also helps you lay the groundwork for deploying zero trust
              security for your district’s cloud applications.
            </p>
            <ol>
              <li>
                <b>Set password policies</b>
                <br></br>
                Passwords are the foundation of any good security plan. Educate
                both students and staff on what factors make passwords strong or
                weak, and why password strength is so important.
                <br></br>As a system admin, you can set policies and standards
                for your district’s cloud app passwords. At a minimum, you
                should enable your system’s “require a strong password” feature.
                You can also set minimum and maximum password lengths, password
                expiration, and more.
                <br></br>If you’re setting the standards for the first time, be
                sure to run a check of current passwords to see whose passwords
                are out of compliance with the new standards. You can then force
                a password change through your admin console.
              </li>
              <li>
                <b>Make multi-factor authentication mandatory</b>
                <br></br>
                Multi-factor authentication requires users to take a second
                step, after entering the correct password, to prove they have
                authorized access. This typically includes entering a code that
                is sent to their phone via SMS. It can also include phone calls,
                answering security questions, mobile app prompts, and more.
              </li>
              <li>
                <b>Manage SaaS access and permissions</b>
                <br></br>
                Open Authorization (OAuth) makes app use convenient for
                end-users, but it can be a little bit of a nightmare for those
                in charge of IT security. The proliferation of SaaS use in
                classNamerooms and throughout school districts makes it
                difficult to stay on top of what apps have access to your cloud
                environment, what permissions are granted to them, and how
                secure the app is itself.
                <br></br>District system admins have the ability to control what
                apps are allowed permissions to the company’s Google or
                Microsoft cloud accounts. This can be as simple as restricting
                access to risky apps, or as customized and detailed as creating
                sanctioned and unsanctioned apps lists..
              </li>
              <li>
                <b> Enable anti-phishing protections</b>
                <br></br>
                Email phishing is still the most common external threat vector.
                And there is a myriad of tools on the market aimed at removing
                phishing emails from inboxes. Unfortunately, none of them work
                with 100% accuracy.
                <br></br>The best option is to start with configuring your
                native cloud email provider’s anti-phishing capabilities and
                then layer additional safeguards and monitors on top of it.
                Educating the rest of your district about common phishing
                attacks, new ones as they arise, and how to spot them is also
                extremely important.
              </li>
              <li>
                <b>
                  Turn on unintended external reply warning password policies
                </b>
                <br></br>
                Passwords are the foundation of any good security plan. Educate
                both students and staff on what factors make passwords strong or
                weak, and why password strength is so important.
                <br></br>When the external reply warning is enabled, users
                receive a pop-up notification asking if they’re sure they want
                to send it to an external domain. It’s important to reinforce to
                your colleagues why they need to pay attention to this pop-up
                and think twice before dismissing it.
              </li>
              <li>
                <b>Set external sharing standards</b>
                <br></br>
                Beyond sending emails, you should configure data loss prevention
                external sharing standards for shared calendars, drives,
                folders, and files. The best approach is to start with the most
                strict standards possible, and then open up as needed.
                <br></br>Files and folders containing the most sensitive
                information such as student, parent/guardian, and staff
                personally identifiable and financial information, should rarely
                (if ever) be configured to allow external sharing and access.
              </li>
              <li>
                <b>Set up message encryption</b>
                <br></br>
                Encryption prevents anyone other than the intended audience from
                viewing a message. Microsoft and Google provide native
                encryption options. In Google’s case, they provide “Confidential
                Mode”, which works a little differently. There are also a
                variety of third party encryption tools available. <br></br>As a
                system admin, you can set policies and standards for your
                district’s cloud app passwords. At a minimum, you should enable
                your system’s “require a strong password” feature. You can also
                set minimum and maximum password lengths, password expiration,
                and more.
                <br></br>Sending sensitive or confidential information via email
                should always have encryption and confidential protections
                enabled. It forces the recipient to authenticate that they are
                the intended audience and protects the information from being
                forwarded to others. The sender can also set up an expiration
                date to ensure the information isn’t lingering in someone’s
                inbox into eternity.
              </li>
              <li>
                <b>Set up data loss prevention policies</b>
                <br></br>
                Fundamentally, data loss prevention is a strategy to ensure that
                your district’s sensitive and protected information does not
                inadvertently leave the network—whether it’s accidental or
                malicious. <br></br>If you’re setting the standards for the
                first time, be sure to run a check of current passwords to see
                whose passwords are out of compliance with the new standards.
                You can then force a password change through your admin console.
              </li>
              <br></br> System admins have the ability to set up data loss
              prevention policies in most popular and “enterprise-level” cloud
              applications. These policies help admins maintain and automate
              rules around how information can be accessed and shared. Most
              policies create alerts and actions that the system can take if a
              data loss prevention policy is broken. For example, if an employee
              account is trying to share a spreadsheet containing social securit
              y numbers with an outside domain, the policy can be set up to
              automatically warn the user and/or quarantine the file.
              <li>
                <b> Enable mobile management</b>
                <br></br>
                Everyone in your school district likely uses mobile devices to
                access school cloud accounts—mainly email, files, and drives.
                These mobile devices represent more endpoints that need to be
                secured by IT. But, endpoint security isn’t enough in cloud
                computing security. You will also need to configure mobile
                device policies in your cloud applications.
              </li>
              <li>
                <b>Run a security health/score audit</b>
                <br></br>
                Once you’ve completed this checklist, it’s a good idea to run a
                cloud security audit of your environment. An audit will re-check
                for any configuration errors, sharing risks, files containing
                sensitive information, and more.
                <br></br>It’s also important to run an audit on a periodic
                basis. Weekly and/or monthly audits and reports can be automated
                and provide you with detailed information into the security
                health of your cloud applications. Microsoft provides Office 365
                Secure Score, which is very helpful in providing on-going health
                checks and recommendations. Particularly as new security
                features are rolled out and new risks are identified.
                <br></br>If your school district uses SaaS applications such as
                G Suite and/or Office 365, cloud application security is a
                critical layer in your cybersecurity infrastructure. Without it,
                monitoring and controlling behavior happening within
                applications are impossible. This blind spot creates critical
                vulnerabilities in your district stakeholders’ sensitive
                information and financial futures.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default securityassessment;
