import React from "react";
import Myimage from "../../assets/images/PUBLICSECTOR1.jpg";

import "../../views/PageContent.css";
import ContactUs from "../../component/contactus";

import ServiceHeader from "../../component/ServiceHeader";
const PublicSector = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="PUBLIC SECTOR" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-8 ">
          <div className="imgg">
            <img src={Myimage} className="Myimg" alt="legal" />
          </div>

          <div className="middle">
            <p>
              <br></br>
              <h5>
                CLOUD ADOPTION IN GOVERNMENT & PUBLIC SECTOR: WHAT’S THE TREND?
              </h5>
              Cloud adoption has been on rise since decades and has now reached
              a level that we don’t see any ‘non-cloud’ IT environment around.
              <br></br>Given this tremendous rise, it’s no exaggeration in
              saying that every industry could be using one or the other form of
              cloud computing methods/processes in their regular activities.
              <br></br>While no industry stands exception to this trend, the
              government sector is getting on to the track on par with others in
              cloud implementation.
              <br></br>The result is what we see around as government data
              stored on cloud, app-driven public services, cloud-hosted online
              services, web interactions and more.
              <br></br>What’s driving governments? The answer lies in the
              cloud’s ability to deliver services efficiently over the network,
              at low costs.
              <br></br>Gartner predicts a two-fold rise in the government
              sector’s rate of public cloud adoption, with spending growing at
              an average of 17.7% annually through 2021.
              <br></br>
            </p>
            <p>
              <h5>GOVERNMENT AND CLOUD ADOPTION TRENDS</h5>
              While companies across most industries spend 20% of their budgets
              on the cloud, that differs in the case of government organizations
              depending on their scope of operations, at the local, state and
              national levels.
              <br></br>As the Gartner reports, local governments spend 20.6% of
              their IT expenditure on cloud, while it is 22% in case of national
              governments.
              <br></br>“The key to successfully implementing cloud in government
              is accounting for the unique technical, organizational, procedural
              and regulatory issues of individual organizations,” says Neville
              Cannon, research director at Gartner.
              <br></br>According to Cannon, national governments consider cloud
              technology as a long-term strategy for achieving IT modernization.
              While local and regional governments look at it as a means of
              achieving immediate benefits such as faster innovation and cost
              savings.
              <br></br>However, local governments are in more proximity to
              enjoying cloud benefits, as that demands only low budgets while
              ultimately helping them in their digital transformation efforts.
              <br></br>
            </p>
            <p>
              <h5>PUBLIC VS PRIVATE CLOUD</h5>
              Public cloud services are much closer to the government sector,
              given the cost and pay-as-you-go models, among others they offer.
              <br></br>But there are a few hindering factors pulling back the
              public cloud adoption, majorly security or privacy, lack of
              diverse features and vendor lock-in issues.
              <br></br>Given these factors, Gartner predicts a positive trend
              for private cloud in the government sector in the years to come,
              with at least a two-fold rise over public cloud through 2021.
              <br></br>“Governments will implement private cloud at twice the
              rate of public cloud through 2021, despite private cloud not
              delivering the same benefits in scale, functionality, cost savings
              or agility as public cloud,” adds Gartner.
              <br></br>
            </p>
            <p>
              <h5>PRIVATE CLOUD, THE NEW LEGACY</h5>
              Government organizations look at the private cloud as a means of
              advanced virtualization or outsourcing critical IT infrastructure
              that eases the maintenance burden.
              <br></br>While there are multiple options that run heavy
              workloads, governments feel private cloud as a special option.
              <br></br>According to Gartner, less than 5% of government private
              clouds are enjoying full cloud features.
              <br></br>“What this highlights is that there are political
              benefits to talking about moving to cloud, even where that
              transition is not taking place in a meaningful way,” explains
              Cannon.
              <br></br>However, poor implementation can cause harm to the public
              as well as governments, ultimately impacting the flow of public
              services, Gartner adds.
              <br></br>
            </p>
            <p>
              <h5>DATA REMAINS CRUCIAL</h5>
              Be it the private or public cloud, now the discussion around is
              all about ‘data security’.
              <br></br>Given this scenario, it’s important for organizations to
              ensure data is strongly protected by relevant firewalls.
              <br></br>But what about the data over the cloud! This is where
              additional security measures, strong regulatory and compliance,
              encryption and data governance are the need of the hour.
              <br></br>Given these data concerns, choosing the right cloud
              option to alleviate security concerns remains a crucial challenge
              for all industries, irrespective of size.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicSector;
