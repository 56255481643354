import React, { useState } from "react";
import axios from "axios";
import "./ContactForm.css"; // Make sure to have a CSS file for styles
import sendEmail from "./EmailSender";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    category: "Other",
    phoneNumber: "",
    message: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setError(""); // Clear error message when the user starts typing
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if all fields have a value
    if (
      !formData.name ||
      !formData.email ||
      formData.category === "Select One" ||
      !formData.phoneNumber ||
      !formData.message
    ) {
      setError("One or more fields have an error. Please check and try again.");
    } else {
      setError("");
      // Prepare the data for the API
      const requestData = {
        inquirer_name: formData.name,
        email: formData.email,
        inquiry_about: formData.category,
        phone_number: formData.phoneNumber,
        message: formData.message,
      };

      try {
        const response = await axios.post(
          // "https://app-acs-dev-eastus.azurewebsites.net/add_inquiry",
          "http://127.0.0.1:5000/add_inquiry",
          requestData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 201) {
          const currentDate = new Date();
          const currentDay = currentDate.getDay();
          let message =
            "Thank you for your inquiry. Our team will contact you within 24 hours.";
          if (currentDay === 6 || currentDay === 0) {
            message =
              "Thank you for your inquiry. Our team will contact you on the next business day.";
          }

          setSuccess(message);
          setFormData({
            name: "",
            email: "",
            category: "Other",
            phoneNumber: "",
            message: "",
          });

          // Send email to sstofficial@sstglobal.net
          const supportEmailSent = await sendEmail({
            application_name: "Inquiry Form",
            env_type: "Production",
            subject: "New Inquiry Received",
            message: `Dear Support Team,

We have received a new inquiry with the following details:

Name: ${formData.name}
Email: ${formData.email}
Inquiry Category: ${formData.category}
Phone Number: ${formData.phoneNumber}
Message: ${formData.message}

Please follow up with the inquirer as soon as possible.

Best regards,
SST Support Team`,
            receiver_addresses: ["sstofficial@sstglobal.net"],
          });

          if (!supportEmailSent) {
            setError(
              "There was a problem sending confirmation emails. Please check your inbox."
            );
          }
        } else {
          setError(
            "There was a problem submitting your inquiry. Please try again later."
          );
        }
      } catch (error) {
        setError(
          "There was a problem submitting your inquiry. Please try again later."
        );
      }
    }
  };

  return (
    <div className="contact-form-container">
      <h2 className="contact-form-header">GET IN TOUCH</h2>
      <div className="contact-form-card">
        {success && <div className="success-message">{success}</div>}
        <form className="contact-form" onSubmit={handleSubmit}>
          <input
            type="text"
            className="form-input"
            id="inputName"
            name="name"
            placeholder="Your Name:"
            value={formData.name}
            onChange={handleChange}
          />
          <input
            type="email"
            className="form-input"
            id="inputEmail"
            name="email"
            placeholder="Your Email:"
            value={formData.email}
            onChange={handleChange}
          />
          <select
            id="inputState"
            className="form-select"
            name="category"
            value={formData.category}
            onChange={handleChange}
          >
            <option value="Other">Other</option>
            <option value="GenAI">GenAI Development</option>
            <option value="Web Development">Web Development</option>
            <option value="Mobile Development">Mobile Development</option>
            <option value="Manage Existing Cloud">Manage Existing Cloud</option>
            <option value="Migrate to the Cloud">Migrate to the Cloud</option>
            <option value="Consulting Advisory">Consulting Advisory</option>
            <option value="Security Assessment & Compliance Related">
              Security Assessment & Compliance Related
            </option>
          </select>
          <input
            type="tel"
            className="form-input"
            id="inputPhone"
            name="phoneNumber"
            placeholder="Your Phone Number:"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
          <textarea
            className="form-input"
            id="inputMessage"
            name="message"
            placeholder="Your Message:"
            value={formData.message}
            onChange={handleChange}
            rows="4"
          ></textarea>
          <button type="submit" className="submit-button">
            SUBMIT NOW
          </button>
          {error && <div className="error-message">{error}</div>}
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
