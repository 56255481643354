import React from "react";
import Myimage from "../../assets/images/Cloud-Assessment.png";
import Myimage1 from "../../assets/images/Infrastructure-assessment.png";
import Myimage2 from "../../assets/images/Data-Centre-Assesment.jpg";
import Myimage3 from "../../assets/images/Hybrid-Cloud-Assessment.jpg";
import Myimage4 from "../../assets/images/Application-Assessment.png";
import Myimage5 from "../../assets/images/database-assessment.png";
import Myimage6 from "../../assets/images/Security-Assessment.jpg";

import "../../views/PageContent.css";
import ServiceHeader from "../../component/ServiceHeader";
import ContactUs from "../../component/contactus";

const assessment = () => {
  return (
    <div className="wrapper">
      {<ServiceHeader title="ASSESSMENTS" />}

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <div className="col-sm-1"></div>

        <div className="col-sm-8">
          <div className="imgg">
            <img src={Myimage} alt="security" />
          </div>

          <div className=" row middle">
            <h3>Assessments</h3>
            <p>
              A <b>cloud</b> readiness <b>assessment</b> is a process where an
              organization looks at its resources and IT environment and
              determines if it is capable of migrating to the cloud. … An
              organization that has ensured it is ready to migrate and has
              formulated a plan on how to do so can reduce the time and money it
              takes to migrate.
            </p>
          </div>
          <div className="IMG">
            <div className=" col-sm-3">
              <img src={Myimage1} alt="security2" className="Myimg" />
            </div>
            <div className=" col-sm-9 rightcont">
              <p>
                <a href="/infrastructureassessment" className="fontcolor">
                  <br />
                  <h4>Infrastructure Assessment</h4>
                </a>
                A cloud readiness assessment is a process, involving an itemized
                list of applications and data that you want to move to the cloud
                and whether these can be moved with minimal impact on your
                business operations, as the transition proceeds.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className=" col-sm-3">
              <img
                align="center"
                src={Myimage2}
                alt="security2"
                className="Myimg"
              />
            </div>
            <div className=" col-sm-9 rightcont">
              <p>
                <a href="/datacenterassessment" className="fontcolor">
                  <br />
                  <h4>Data Centers Assessment</h4>
                </a>
                Whether you’re looking to optimize your resources, to reduce
                your data center risk, or to prepare for hybrid computing—or to
                accomplish all three—our cloud and data center assessments
                provide actionable insights about your environment so you can
                deliver better a digital experience for your users.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className=" col-sm-3">
              <img
                align="center"
                src={Myimage3}
                alt="security2"
                className="Myimg"
              />
            </div>
            <div className=" col-sm-9 rightcont">
              <p>
                <a href="/hybridcloudassessment" className="fontcolor">
                  <br />
                  <h4>Hybrid Cloud Assessment</h4>
                </a>
                The Hybrid Cloud Assessment is the fastest way to start planning
                your cloud migration strategy.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className=" col-sm-3">
              <img
                align="center"
                src={Myimage4}
                alt="security2"
                className="Myimg"
              />
            </div>
            <div className=" col-sm-9 rightcont">
              <p>
                <a href="/appassessment" className="fontcolor">
                  <br /> <h4>Application Assessment</h4>
                </a>
                The expertise required to modernize applications so enterprises
                can accelerate time-to-market and reduce development costs. From
                holistic, cloud-native architecture, flexible data stores, or
                built-in monitoring capabilities, we will help you clarify how
                to power and protect your organization.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className=" col-sm-3">
              <img
                align="center"
                src={Myimage5}
                alt="security2"
                className="Myimg"
              />
            </div>
            <div className=" col-sm-9 rightcont">
              <p>
                <a href="databaseassessment" className="fontcolor">
                  <br />
                  <h4>Database Assessment</h4>
                </a>
                The ability to manage databases in-house is inadequate. The key
                advantages to cloud database migration are availability,
                scalability, reliability, and cost. The cloud infrastructure is
                scalable, and no Capex investment is needed. Businesses are
                generally very open to database migration if security concerns
                are addressed satisfactorily.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className=" col-sm-3">
              <img
                align="center"
                src={Myimage6}
                alt="security2"
                className="Myimg"
              />
            </div>
            <div className=" col-sm-9 rightcont">
              <p>
                <a href="/securityassessment" className="fontcolor">
                  <br /> <h4>Security Assessment</h4>
                </a>
                It is a series of defined policies, processes, controls, and
                technology governing all information exchanges that happen in
                collaborative cloud Software as a Service (SaaS) applications
                like Microsoft Office 365 and Google G Suite.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default assessment;
