import React from "react";
import PropTypes from "prop-types";
import "./ServiceHeader.css";

const ServiceHeader = ({ title }) => {
  return (
    <>
      <div className="header">
        <h2>{title}</h2>
      </div>
      <div className="header-line"></div>
    </>
  );
};

ServiceHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ServiceHeader;
