import ServiceHeader from "../../component/ServiceHeader";
import "../PageContent.css";
import ContactUs from "../../component/contactus";
import img from "../../assets/images/dts.jpg";

const WebApplicationDevelopment = () => {
  return (
    <div>
      <ServiceHeader title="Web Application Development" />
      
        {/* <div className="row4">h</div> */}

        <div className="row mp">
          <div className="col-sm-3 mpc">
            <ContactUs />
          </div>
          <div className="col-sm-1"></div>
          <div className="col-sm-8">
            <div className="imgg">
              <img align="right" src={img} />
            </div>
            <div className="row middle">
              <h2>WEB APPLICATION DEVELOPMENT</h2>
              <p>
                Regardless of how complex or even small your needs are , our
                dedicated in-house team of web developers and project managers
                will ensure that the job gets done with the highest level of
                professionalism and most cost affordably. CMS based Websites
                <br />
                We have experience building websites for almost every industry
                and on almost every platform including WordPress, Drupal and
                Joomla.
                <br />
                <br />
                <b>ECommerce Applications</b>
                <br />
                If you have a store or need new one, we can surely help you with
                the same. We can create a store for you from scratch or,
                leverage popular platforms such as WooCommerce, Shopify or
                Magento.
                <br />
                <br />
                <b>API Development and Documentation</b>
                <br />
                We can help you if you need to build or, consume an API. Our
                teams have worked with all sort of APIs including Voice, Video,
                Payment, Cloud, Accounting etc.
                <br />
                <br />
                <b>Website Rescue and Optimisation</b>
                <br />
                Is your website hacked or running slow? Our cross sectional team
                of developers and hosting experts can help rescue it and prevent
                further issues.
                <br />
                <br />
                <b>Web Applications</b>
                <br />
                Being a web development company, we have built all sorts of web
                applications from online forms, workflows to whole management
                systems. We work on projects both big and small.
                <br />
                <br />
                <b>Software Development</b>
                <br />
                Our engineering team has a lot of experience in developing
                complex web based business systems with multiple users and roles
                and that connect with CRM, Accounting, Inventory and Other
                Systems.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
   
  );
};
export default WebApplicationDevelopment;
