import React from "react";
import "../PageContent.css";
import PolicySST from "../../assets/images/Policy-Compliance-SST.jpg";
import ServiceHeader from "../../component/ServiceHeader";
import ContactUs from "../../component/contactus";

const Policy = () => {
  return (
    <div  className="wrapper" >
      <ServiceHeader title="Policy" />

        <div className="row mp">
          <div className="col-sm-3 mpc">
            <ContactUs />
          </div>
      <div className="col-sm-1"></div>
          <div className="col-sm-8">
            <div className="imgg">
              <img
                className="policy-img"
                src={PolicySST}
                alt="Policy_SST_img"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
            </div>
            <div className="row middle">
              <p>
                EducationApp built the EducationApp as a Free app. This SERVICE
                is provided by EducationApp at no cost and is intended for use
                as is.
              </p>
              <p>
                This page is used to inform visitors regarding our policies with
                the collection, use, and disclosure of Personal Information if
                anyone decided to use our Service.
              </p>
              <p>
                If you choose to use our Service, then you agree to the
                collection and use of information in relation to this policy.
                The Personal Information that we collect is used for providing
                and improving the Service. We will not use or share your
                information with anyone except as described in this Privacy
                Policy.
              </p>
              <p>
                For a better experience, while using our Service, we may require
                you to provide us with certain personally identifiable
                information, including but not limited to the use of the camera.
                The information that we request will be retained on your device
                and is not collected by us in any way.
              </p>
              <p>
                The app does use third-party services that may collect
                information used to identify you.
              </p>
              <p>
                Our app requires access to your device's camera for the
                following purposes:
              </p>
              <p>
                To capture photos while recording the lecture in order to take /
                capture the notes. The images captured by the camera are stored
                locally on your device and are not shared with us or any third
                parties without your consent.
              </p>
            </div>
          </div>
        </div>
      </div>
  
  );
};

export default Policy;
