import React from "react";
import img from "../assets/images/rpo.jpg";
import img1 from "../assets/images/rpo1.jpg";
import img2 from "../assets/images/rpo2.png";
import img3 from "../assets/images/rpo3.jpg";
import img4 from "../assets/images/rpo4.jpg";
import img5 from "../assets/images/rpo5.jpg";
import img6 from "../assets/images/rpo6.gif";
import img7 from "../assets/images/rpo7.jpg";
import ContactUs from "../component/contactus";
import ServiceHeader from "../component/ServiceHeader";
import "./PageContent.css";

const RPO = () => {
  return (
    <div className="wrapper">
      <ServiceHeader title="RECRUITMENT PROCESS OUTSOURCING" />

      <div className="row mp">
        <div className="col-sm-3 mpc">
          <ContactUs />
        </div>
        <dic className="col-sm-1"></dic>
        <div className="col-sm-8">
          <div className="imgg">
            <img align="right" src={img} />
          </div>
          <div className="row middle">
            <br />
            <h2>What Is Recruitment Process Outsourcing?</h2>
            <p>
              Recruitment process outsourcing (RPO) programs are known for
              providing talent acquisition services for both professional and
              non-professional positions that solve scalability, cost,
              efficiency, quality and many other recruiting challenges. RPO
              providers are subject-matter-experts; talent acquisition is their
              core competency. They know how to navigate the recruiting
              environment while utilizing expertise, innovative technology and
              key performance indicators and have the resources to scale
              recruiting capacity to meet hiring demands.
              <br />
              <br />
              Time is money. And every day a company goes without filling a
              position is a day that company is not operating at maximum
              capacity. Through clearly defined processes and deep
              specialization, RPO solutions can dramatically reduce
              cost-per-hire, time-to-hire, and attrition, plus measurably
              improve quality of hire, reporting, and scalability, delivering
              you a far greater return on investment
            </p>
            <br />
            <h4>The Benefits Of Shri Sai RPO Services</h4>
          </div>

          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img1} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a href="" className="fontcolor">
                <br />
                <h4>Reduced Recruiting Costs</h4>
              </a>

              <p>
                Many companies spend a lot of money on head-hunters and job
                boards, or waste a lot of money through high turnover rates.
                With other companies, time is simply wasted in lengthy hiring
                processes or outdated (or a lack of) technology. Shri Sai Tech
                (SST) RPO Services streamlines the multi-step process of
                recruitment, thereby saving you a significant amount of money by
                using our software for application tracking and management.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img2} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a href="" className="fontcolor">
                <br />
                <h4>Less Burden On Hr Department</h4>
              </a>

              <p>
                Employing SST RPO Services will ease the strain on the human
                resources department and significantly improve its efficiency
                and productivity. When all aspects of recruiting are channelled
                through an us, HR will be free to entirely focus on tackling
                internal issues arising within the organization.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img3} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a href="" className="fontcolor">
                <br />
                <br />
                <h4>Providing Access To An Exclusive Talent Pool</h4>
              </a>
              <p>
                Being in the business for several years, we have developed a
                detailed, extensive database of hard to find personnel needed to
                fill crucial job positions. When coupled with our exclusive
                access to these highly coveted talents, it enable our client
                companies to secure the services of in-demand candidates more
                easily
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img4} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a href="" className="fontcolor">
                <br />
                <h4>Flexibility</h4>
              </a>

              <p>
                HR departments are being asked to do more with less. Our
                solutions have the flexibility to react and respond in size,
                shape and scale in the face of changing hiring demands.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img5} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a href="" className="fontcolor">
                <br />
                <h4>Scalability</h4>
              </a>

              <p>
                SST RPO Services understands that hiring volumes may fluctuate
                greatly due to both internal and external forces. Our
                customizable solutions allow you to scale your outsourcing up or
                down during high and low volume periods.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img6} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a href="" className="fontcolor">
                <br />
                <h4>Employer Branding</h4>
              </a>

              <p>
                SST RPO Services can help to develop and build your employer
                brand. As well as this, they can make sure your employer brand
                is effectively represented through the recruitment process and
                amplify it with an approach that focuses on the candidate
                experience.
              </p>
            </div>
          </div>
          <div className="IMG">
            <div className="col-sm-3">
              <img align="center" src={img7} className="Myimg" />
            </div>
            <div className="col-sm-9 rightcont">
              <a href="" className="fontcolor">
                <br />
                <h4>Seamless Compliance</h4>
              </a>

              <p>
                SST RPO Service’s rigorous processes ensure a legally compliant
                hiring process and streamlined responses to audits.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RPO;
